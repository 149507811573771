export const ADD_NEW_SESSION = 'ADD_NEW_SESSION';
export const FIND_AND_REPLACE_SESSION = 'FIND_AND_REPLACE_SESSION';
export const REMOVE_ENDED_SESSION = 'REMOVE_ENDED_SESSION';
export const SET_GROUPED_SESSIONS = 'SET_GROUPED_SESSIONS';
export const SET_LOG_SEARCH_PARAMS = 'SET_LOG_SEARCH_PARAMS';
export const SET_LOG_SEARCH_RESULTS = 'SET_LOG_SEARCH_RESULTS';
export const SET_SESSIONS = 'SET_SESSIONS';
export const SET_SESSIONS_LOADING = 'SET_SESSIONS_LOADING';
export const SET_SOCKET = 'SET_SOCKET';
export const SET_USER = 'SET_USER';
export const SET_SESSION_TO_VIEW = 'SET_SESSION_TO_VIEW';
export const SET_SESSION_TO_VIEW_ID = 'SET_SESSION_TO_VIEW_ID';
export const SET_SELECTED_CUSTOMER = 'SET_SELECTED_CUSTOMER';
export const SET_SELECTED_CUSTOMER_ID = 'SET_SELECTED_CUSTOMER_ID';
export const SET_SELECTED_PLAN = 'SET_SELECTED_PLAN';
export const SET_SELECTED_STEP = 'SET_SELECTED_STEP';

import {
    ADD_NEW_SESSION,
    FIND_AND_REPLACE_SESSION,
    REMOVE_ENDED_SESSION,
    SET_GROUPED_SESSIONS,
    SET_LOG_SEARCH_PARAMS,
    SET_LOG_SEARCH_RESULTS,
    SET_SELECTED_CUSTOMER,
    SET_SELECTED_CUSTOMER_ID,
    SET_SELECTED_PLAN,
    SET_SELECTED_STEP,
    SET_SESSIONS,
    SET_SESSIONS_LOADING,
    SET_SESSION_TO_VIEW,
    SET_SESSION_TO_VIEW_ID,
    SET_SOCKET,
    SET_USER
} from './actionTypes';

// User, socket, etc
export const setUser = (userInfo) => ({
    type: SET_USER,
    payload: userInfo
});

export const setSocket = (socket) => ({
    type: SET_SOCKET,
    payload: socket
});

// Log page
export const setLogSearchParams = (logSearchParams) => ({
    type: SET_LOG_SEARCH_PARAMS,
    payload: logSearchParams
});

export const setLogSearchResults = (logSearchResults) => ({
    type: SET_LOG_SEARCH_RESULTS,
    payload: logSearchResults
});

// Sessions
export const addNewSession = (session) => ({
    type: ADD_NEW_SESSION,
    payload: session
});

export const findAndReplaceSession = (session) => ({
    type: FIND_AND_REPLACE_SESSION,
    payload: session
});

export const removeEndedSession = (session) => ({
    type: REMOVE_ENDED_SESSION,
    payload: session
});

export const setSessions = (sessions) => ({
    type: SET_SESSIONS,
    payload: sessions
});

export const setSessionsLoading = (loading) => ({
    type: SET_SESSIONS_LOADING,
    payload: loading
});

export const setGroupedSessions = (sessions) => ({
    type: SET_GROUPED_SESSIONS,
    payload: sessions
});

export const setSessionToView = (sessionToView) => ({
    type: SET_SESSION_TO_VIEW,
    payload: sessionToView
});

export const setSessionToViewId = (sessionToViewId) => ({
    type: SET_SESSION_TO_VIEW_ID,
    payload: sessionToViewId
});

// Action Plan
export const setSelectedCustomer = (selectedCustomer) => ({
    type: SET_SELECTED_CUSTOMER,
    payload: selectedCustomer
});

export const setSelectedCustomerId = (selectedCustomerId) => ({
    type: SET_SELECTED_CUSTOMER_ID,
    payload: selectedCustomerId
});

export const setSelectedPlan = (selectedPlan) => ({
    type: SET_SELECTED_PLAN,
    payload: selectedPlan
});

export const setSelectedStep = (selectedStep) => ({
    type: SET_SELECTED_STEP,
    payload: selectedStep
});

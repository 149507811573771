import { includes } from 'lodash';
import React, { useState } from 'react';
import { FaCaretDown } from 'react-icons/fa';
import { useSelector } from 'react-redux';

import { SESSION_TYPES, STATES } from '../../../config/constants';
import CY from '../../../config/cypressConstants';
import { getUser } from '../../../redux/selectors';

export default function SessionActionButtons({
    acknowledgeSession,
    endSessionProcess,
    session,
    setShowExtendSession
}) {
    // State
    const [ackButtonClass, _setAckButtonClass] = useState('black');
    const [showEndSessionDropdown, setShowEndSessionDropdown] = useState(false);

    // Redux
    const user = useSelector(getUser);

    function toggleEndSessionDropdown() {
        setShowEndSessionDropdown((currVal) => !currVal);
    }

    function _showAcknowledgeButton() {
        const {
            alarm,
            alarmTimerStarted,
            expired,
            heartbeatTimeout,
            paused,
            sessionEnded
        } = STATES;
        if (!session || session.state === sessionEnded) {
            return false;
        }
        const validStates = [
            alarm,
            alarmTimerStarted,
            expired,
            heartbeatTimeout,
            paused
        ];
        return (
            session &&
            session.actionPlan &&
            !session.staffAssignee &&
            includes(validStates, session.state)
        );
    }

    function _showEndSessionButton() {
        return session && session.state !== STATES.sessionEnded;
    }

    function _showExtendSessionButton() {
        return (
            session &&
            session.expirationDate &&
            session.state !== STATES.sessionEnded &&
            session.sessionType !== SESSION_TYPES.timer
        );
    }

    return (
        <div className="ActionButtons-container">
            <div className="ActionButtons-dropdown-container">
                {_showExtendSessionButton() && (
                    <button
                        className="button light-blue"
                        onClick={() => setShowExtendSession(true)}
                        data-testid={CY.SESSION_DETAILS.SESSION_EXTEND_BUTTON}
                    >
                        Extend Session
                    </button>
                )}
                {/* TODO: there is some logic in angular for making the ack button blink... but unclear when it actually gets applied */}
                {_showAcknowledgeButton() && (
                    <button
                        className={`button ${ackButtonClass}`}
                        onClick={acknowledgeSession}
                    >
                        Acknowledge
                    </button>
                )}
                {_showEndSessionButton() && (
                    <button
                        className="button dark-blue"
                        id="EndSession-button"
                        onClick={toggleEndSessionDropdown}
                        data-testid={CY.SESSION_DETAILS.SESSION_END_BUTTON}
                    >
                        End Session <FaCaretDown />
                    </button>
                )}
                {/* TODO: make sure this is accessible */}
                {showEndSessionDropdown && (
                    <ul
                        role="menu"
                        aria-labelledby="EndSession-button"
                        id="EndSession-dropdown"
                    >
                        <li
                            role="menuitem"
                            id="falseAlarmOption"
                            onClick={() => endSessionProcess('falseAlarm')}
                        >
                            False Alarm
                        </li>
                        <li
                            role="menuitem"
                            id="dispatchCompletedOption"
                            onClick={() =>
                                endSessionProcess('dispatchCompleted')
                            }
                        >
                            Dispatch Completed
                        </li>
                        <li
                            role="menuitem"
                            id="dispatchCompletedEmergencyOption"
                            onClick={() =>
                                endSessionProcess('dispatchCompleted', {
                                    emergencyDispatch: true
                                })
                            }
                        >
                            Dispatch Completed (Emergency)
                        </li>
                        {user.isMCAdmin && (
                            <li
                                role="menuitem"
                                id="lostSignalOption"
                                onClick={() => endSessionProcess('lostSignal')}
                            >
                                Lost Signal
                            </li>
                        )}
                        <li
                            role="menuitem"
                            onClick={() => endSessionProcess('staffEnded')}
                            id="endSessionNoActionOption"
                        >
                            Staff Ended
                        </li>
                    </ul>
                )}
            </div>
        </div>
    );
}

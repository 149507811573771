import '../styling/Login.css';

import axios from 'axios';
import { Buffer } from 'buffer';
import { isFunction } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import { FaLock, FaSpinner } from 'react-icons/fa';
import { useDispatch } from 'react-redux';

import { MC_USER_SESSION_COOKIE_NAME } from '../config/constants';
import CY from '../config/cypressConstants';
import useSocket from '../hooks/useSocket';
import { setUser } from '../redux/actions';

export default function Login({ onLogin }) {
    // State
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [errMsg, setErrMsg] = useState('');
    const [_cookies, setCookie] = useCookies([MC_USER_SESSION_COOKIE_NAME]);

    // Redux
    const dispatch = useDispatch();
    const socket = useSocket();

    // Refs
    const userRef = useRef();
    const errRef = useRef();

    useEffect(() => {
        userRef.current.focus();
    }, []);
    useEffect(() => {
        setErrMsg('');
    }, [username, password]);

    async function handleLogin(e) {
        e.preventDefault();
        setLoading(true);
        try {
            const response = await axios.post(
                '/api/auth/staff/login',
                JSON.stringify({ username, password }),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                }
            );

            // Set user to store
            dispatch(setUser(response.data.user));

            // Reset form
            setUsername('');
            setPassword('');
            setLoading(false);
            setCookie(
                MC_USER_SESSION_COOKIE_NAME,
                Buffer.from(JSON.stringify(response.data.user)).toString(
                    'base64'
                )
            );

            // This prop can be used to handle any side-effects that should
            //  happen after succesful login (such as registering the socket)
            if (isFunction(onLogin)) onLogin();
        } catch (err) {
            setLoading(false);
            if (!err?.response) {
                setErrMsg('No Server Response');
            } else if (err.response?.status === 400) {
                setErrMsg('Missing Username or Password');
            } else if (err.response?.status === 401) {
                setErrMsg('Unauthorized');
            } else {
                setErrMsg('Login Failed');
            }
            errRef.current.focus();
        }
    }

    return (
        <div className="Login-container">
            <header className="Login-header">
                <div className="Login-header-text">
                    <h2>Log in to Monitoring Center</h2>
                    <p>Enter your username and password to log in</p>
                </div>
                <FaLock className="lock-icon" />
            </header>
            <form onSubmit={handleLogin} className="Login-form">
                {errMsg && (
                    <p
                        ref={errRef}
                        className="Error-message"
                        aria-live="assertive"
                    >
                        {errMsg}
                    </p>
                )}
                <input
                    placeholder="Username"
                    type="text"
                    id="username"
                    ref={userRef}
                    autoComplete="off"
                    onChange={(e) => setUsername(e.target.value)}
                    value={username}
                    required
                    data-testid={CY.LOGIN_PAGE.LOGIN_USERNAME}
                />
                <input
                    placeholder="Password"
                    type="password"
                    id="password"
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                    required
                    data-testid={CY.LOGIN_PAGE.LOGIN_PASSWORD}
                />
                <button data-testid={CY.LOGIN_PAGE.LOGIN_BUTTON}>
                    {loading && !socket ? (
                        <FaSpinner className="spinner" />
                    ) : (
                        'login'
                    )}
                </button>
            </form>
        </div>
    );
}

import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';

import { SESSION_TYPES } from '../../../config/constants';
import usePrevious from '../../../hooks/usePrevious';

export default function Counter({ date, sessionType }) {
    const [counter, setCounter] = useState();
    const prevDate = usePrevious(date);

    let ticker;

    useEffect(() => {
        if (isEmpty(prevDate) && !isEmpty(date)) {
            // On first load, upate counter
            updateCounter();
            ticker = setInterval(updateCounter, 1000);
        } else if (prevDate !== date) {
            // If the date changes, restart the counter
            setCounter(0);
            clearInterval(ticker);
            updateCounter();
            ticker = setInterval(updateCounter, 1000);
        }
        return () => clearInterval(ticker);
    }, [date]);

    function pad(num, size) {
        let s = `00${num}`;
        return s.substr(s.length - size);
    }

    function updateCounter() {
        let secondsDiff = Math.round((new Date(date) - new Date()) / 1000);
        if (secondsDiff <= 0 && sessionType === SESSION_TYPES.timer) {
            setCounter('0:00:00');
            return;
        }
        secondsDiff = Math.abs(secondsDiff);
        const seconds = secondsDiff % 60;
        const minutes = Math.floor((secondsDiff % 3600) / 60);
        const hours = Math.floor(secondsDiff / 3600);
        setCounter(`${hours}:${pad(minutes, 2)}:${pad(seconds, 2)}`);
    }

    return <div>{counter}</div>;
}

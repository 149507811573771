import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import CY from '../../config/cypressConstants';
import useSocket from '../../hooks/useSocket';
import {
    getSelectedCustomerId,
    getSelectedPlan,
    getUser
} from '../../redux/selectors';

export default function ActionPlanTitleForm() {
    // Redux
    const selectedPlan = useSelector(getSelectedPlan);
    const customerId = useSelector(getSelectedCustomerId);
    const user = useSelector(getUser);

    // State
    const [title, setTitle] = useState(selectedPlan?.title);

    // Hooks
    const socket = useSocket();

    useEffect(() => {
        // Whenever the selected plan changes, the title should change
        setTitle(selectedPlan?.title);
    }, [selectedPlan]);

    function handleChange(e) {
        setTitle(e.target.value);
    }

    function handleSubmit(e) {
        e.preventDefault();
        socket.emit('editActionPlanTitle', {
            customerId,
            planName: selectedPlan?.name,
            planTitle: title
        });
    }

    return (
        <form
            id="ActionPlanTitleForm"
            onSubmit={handleSubmit}
            data-testid={CY.ACTION_PLANS_PAGE.TITLE_FORM}
        >
            <label htmlFor="title" className="ActionPlanForm-label">
                Title:
            </label>
            <input
                type="text"
                id="title"
                className="ActionPlanEditor-input"
                disabled={!user.isMCAdmin}
                value={title}
                onChange={handleChange}
                data-testid={CY.ACTION_PLANS_PAGE.TITLE_INPUT}
            />
            {user.isMCAdmin && (
                <input
                    type="submit"
                    value="Save Title"
                    className="button dark-blue"
                    data-testid={CY.ACTION_PLANS_PAGE.SAVE_TITLE_BUTTON}
                />
            )}
        </form>
    );
}

export default {
    LOGIN_PAGE: {
        LOGIN_BUTTON: 'login-button',
        LOGIN_USERNAME: 'login-username',
        LOGIN_PASSWORD: 'login-password'
    },

    MONITOR_PAGE: {
        HEADER: 'page-headers',
        SESSION_SUMMARY_PANEL: 'session-summary-panel',
        SESSION_COMPANY: 'session-company',
        SESSION_PERSON: 'session-person',
        SESSION_ACTION: 'session-action',
        SESSION_START_DATE: 'session-start-date',
        SESSION_LAST_HEARTBEAT_DATE: 'session-last-heartbeat-date'
    },

    SESSION_DETAILS: {
        SESSION_ICAD_SAVE_BUTTON: 'session-icad-save-button',
        SESSION_END_BUTTON: 'session-end-button',
        SESSION_EXTEND_BUTTON: 'session-extend-button',
        SESSION_DONE_BUTTON: 'session-done-button',
        SESSION_CANCEL_END_BUTTON: 'session-cancel-end-button',
        SESSION_EVENTS_STAFF_NOTES: 'session-events-staff-notes',
        SESSION_EVENTS_LOCATION_CHANGES_CHECKBOX:
            'session-events-location-changes-checkbox',
        SESSION_EVENTS_HEARTBEATS_CHECKBOX:
            'session-events-heartbeats-checkbox',
        SESSION_USER_INFO_HEADER: 'session-user-info-header',
        SESSION_STICKY_NOTE_TITLE: 'session-sticky-note-title',
        SESSION_STICKY_NOTE_BODY: 'session-sticky-note-body',
        SESSION_STICKY_NOTE_PENCIL_ICON: 'session-sticky-note-pencil-icon',
        SESSION_EVENTS_ICAD_LABEL: 'session-events-icad-label',
        SESSION_EVENTS_ICAD_INPUT: 'session-events-icad-input',
        SESSION_USER_INFO_IMAGE: 'session-user-info-image',
        SESSION_USER_DEVICE_INFO: 'session_user_device_info',
        SESSION_EVENTS_HEADER: 'session-events-header'
    },

    DASHBOARD_PAGE: {
        DASHBOARD_STAT_CELL: 'dashboard-stat-cell',
        DASHBOARD_DAILY_TOTALS: 'dashboard-daily-totals',
        RECENT_ALARM_TABLE: 'recent-alarm-table'
    },

    LOG_PAGE: {
        CUSTOMER_SEARCH_BOX: 'customer-search-box',
        USER_SEARCH_BOX: 'user-search-box',
        CALENDAR_PICKER_START_DATE: 'start-date-calendar-picker',
        CALENDAR_PICKER_END_DATE: 'end-date-calendar-picker'
    },
    // will be updated values
    ACTION_PLANS_PAGE: {
        SAVE_CONFIGURATION_BUTTON: 'save-configuration-button',
        SAVE_TITLE_BUTTON: 'save-title-button',
        SAVE_STEP_BUTTON: 'save-step-button',
        TITLE_FORM: 'title-form',
        STEP_FORM: 'step-form',
        TITLE_INPUT: 'title-input',
        STEP_INPUT: 'step-input'
    },

    PSAP_MAP_PAGE: {
        PSAP_MAP: 'psap-map',
        PSAP_MAP_SEARCH: 'psap-map-search',
        PSAP_INFO_CLOSE: 'psap-info-close'
    },

    BUTTONS: {
        LOGOUT_BUTTON: 'logout-button',
        SEARCH_BUTTON: 'search-button',
        ACKNOWLEDGE_BUTTON: 'acknowledge-button'
    }
};

export function createSessionId(session) {
    return `${session.startDate}${session.user.userId}`;
}

import '../../../styling/SessionEvents.css';
import '../../../styling/Session-shared.css';

import dayjs from 'dayjs';
import { filter, includes, map, orderBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import {
    FaCheckCircle,
    FaChevronDown,
    FaChevronRight,
    FaPencilAlt
} from 'react-icons/fa';
import { useSelector } from 'react-redux';

import gpsLocationIcon from '../../../assets/images/ic_gps_location.png';
import {
    ACTIONS,
    ACTIONS_DISPLAY,
    STATES_DISPLAY
} from '../../../config/constants';
import CY from '../../../config/cypressConstants';
import useSocket from '../../../hooks/useSocket';
import { getUser } from '../../../redux/selectors';
import { formatPhone } from '../../../utils';

export default function SessionEvents({ session, events }) {
    // State
    const [filteredEvents, setFilteredEvents] = useState(filterAndSortEvents());
    const [showHeartbeats, setShowHeartbeats] = useState(false);
    const [showLocations, setShowLocations] = useState(false);
    const [showICAD, setShowICAD] = useState(false);
    // We need to track which event the note belongs to since multiple events could have notes
    const [eventNote, setEventNote] = useState({ note: '', event: null });
    const [sessionNote, setSessionNote] = useState(
        // We must supply the '' bc if session.note is undefined, React will get mad
        //  (changing an uncontrolled input to be controlled)
        session && session.note ? session.note : ''
    );
    const [sessionICAD, setSessionICAD] = useState(
        session && session.ICAD ? session.ICAD : ''
    );
    const [savingICAD, setSavingICAD] = useState(false);

    // Redux
    const user = useSelector(getUser);
    const socket = useSocket({
        listeners: [
            [
                'icadSaved',
                (data) => {
                    if (data === 'success') {
                        setTimeout(() => {
                            setShowICAD(false);
                            setSavingICAD(false);
                        }, 500);
                    }
                }
            ]
        ]
    });

    useEffect(() => {
        setFilteredEvents(filterAndSortEvents());
    }, [events, showHeartbeats, showLocations]);

    function filterAndSortEvents() {
        return orderBy(
            filter(
                events,
                (event) =>
                    (showHeartbeats || event.action !== ACTIONS.heartbeat) &&
                    (showLocations || event.action !== ACTIONS.location)
            ),
            ['date'],
            ['desc']
        );
    }

    function toggleHeartbeats(e) {
        setShowHeartbeats(e.target.checked);
    }

    function toggleLocations(e) {
        setShowLocations(e.target.checked);
    }

    function isPsap(actor) {
        return includes(['psap', 'fakePsap'], actor);
    }

    function updateSessionNote(e) {
        setSessionNote(e.target.value);
    }

    function updateEventNote(e, event) {
        setEventNote({ note: e.target.value, event });
    }

    function handleNoteSubmit(e) {
        e.preventDefault();
        saveEventNote();
    }

    function saveEventNote() {
        const socketEvent = eventNote?.event
            ? 'updateEventNote'
            : 'createSessionNote';
        const note = eventNote?.event ? eventNote.note : sessionNote;
        // TODO: update this when sessionId is modified
        socket.emit(socketEvent, {
            startDate: session.startDate,
            userId: session.user.userId,
            note,
            date: eventNote?.event?.date
        });
        setEventNote({ note: '', event: null });
        setSessionNote('');
    }

    function canEditStaffNotes() {
        return user.isMCAdmin && !session.viewOnly;
    }

    function saveICAD(e) {
        e.preventDefault();
        // TODO: update this when sessionId is modified
        socket.emit('saveICAD', {
            icad: sessionICAD,
            userId: session.userId,
            startDate: session.startDate
        });
        setSavingICAD(true);
    }

    function toggleICAD() {
        setShowICAD((currVal) => !currVal);
    }

    function handleICADChange(e) {
        setSessionICAD(e.target.value);
        return;
    }

    function renderEventHeader(event) {
        const formattedEventDate = dayjs(event.date).format('h:mm:ss A');
        let content;

        if (event.action === ACTIONS.actionPlanStepCompleted) {
            content = (
                <>
                    {formattedEventDate} - {event.step.title}
                </>
            );
        } else {
            content = (
                <>
                    {formattedEventDate}
                    {event.state && ` - ${STATES_DISPLAY[event.state]}`}
                    {event.action && ` - ${ACTIONS_DISPLAY[event.action]}`}
                    {event.staff && (
                        <span>
                            {' '}
                            - {event.staff.firstName} {event.staff.lastName}
                        </span>
                    )}
                </>
            );
        }
        return (
            <h4
                className="SessionEvents-event-header"
                data-testid={CY.SESSION_DETAILS.SESSION_EVENTS_HEADER}
            >
                {content}
            </h4>
        );
    }

    function renderEventBody(event) {
        switch (event.action) {
            case ACTIONS.updateStaffNote:
                return (
                    <div className="SessionEvents-event-body">
                        <div>
                            Note from{' '}
                            {dayjs(event.prevNoteDate).format('h:mm:ss A')}
                        </div>
                        <div>
                            <span className="strikethrough">
                                {event.prevNote}
                            </span>{' '}
                            <span>{event.currNote}</span>
                        </div>
                    </div>
                );
            case ACTIONS.staffNote:
                return (
                    <div className="SessionEvents-event-body">
                        {eventNote.note && eventNote.event === event ? (
                            <form onSubmit={handleNoteSubmit}>
                                <input
                                    type="text"
                                    value={eventNote.note}
                                    // placeholder={'Hit enter to submit.'}
                                    onChange={(e) => updateEventNote(e, event)}
                                    className="Session-notes-input"
                                />
                            </form>
                        ) : (
                            <div>
                                {event.note}{' '}
                                {canEditStaffNotes() && (
                                    <FaPencilAlt
                                        className="cursor-pointer"
                                        onClick={() =>
                                            setEventNote({
                                                note: event.note,
                                                event
                                            })
                                        }
                                    />
                                )}
                            </div>
                        )}
                    </div>
                );
            case ACTIONS.actionPlanStepCompleted:
                return event.actor.email ? (
                    <div className="SessionEvents-event-body">
                        Completed by{' '}
                        {event.actor.firstName || event.actor.first_name}{' '}
                        {event.actor.lastName || event.actor.last_name}
                    </div>
                ) : (
                    <div className="SessionEvents-event-body">
                        {isPsap(event.actor) ? (
                            <div>
                                <div>{event.agency}</div>
                                <div>{formatPhone(event.phone)}</div>
                            </div>
                        ) : (
                            <div>Completed Automatically</div>
                        )}
                    </div>
                );
            case ACTIONS.location:
                return (
                    <div className="SessionEvents-event-body">
                        <img src={gpsLocationIcon} />{' '}
                        <a
                            href={`https://www.google.com/maps/search/?api=1&query=${event.latitude},${event.longitude}`}
                            target="_blank"
                            rel="noreferrer"
                        >
                            ({event.latitude}, {event.longitude})
                        </a>
                        <div className="SessionEvents-event-accuracy">
                            Accuracy: {event.accuracy}
                        </div>
                    </div>
                );
        }
    }

    function renderEvent(event) {
        return (
            <div
                className="SessionEvents-event"
                key={`${event.action}-${event.date}`}
            >
                {renderEventHeader(event)}
                {renderEventBody(event)}
            </div>
        );
    }

    return (
        <div className="SessionDetails-section">
            <h3>Recent Actions and Events</h3>
            <div className="SessionEvents-filters row">
                <div>
                    <input
                        type="checkbox"
                        value={showHeartbeats}
                        onChange={toggleHeartbeats}
                        id="hearbeats"
                        data-testid={
                            CY.SESSION_DETAILS
                                .SESSION_EVENTS_HEARTBEATS_CHECKBOX
                        }
                    />{' '}
                    <label htmlFor="hearbeats">Show Heartbeats</label>
                </div>
                <div>
                    <input
                        type="checkbox"
                        id="location-changes"
                        value={showLocations}
                        onChange={toggleLocations}
                        data-testid={
                            CY.SESSION_DETAILS
                                .SESSION_EVENTS_LOCATION_CHANGES_CHECKBOX
                        }
                    />{' '}
                    <label htmlFor="location-changes">
                        Show Location Changes
                    </label>
                </div>
            </div>
            <form onSubmit={handleNoteSubmit} className="row">
                <input
                    type="text"
                    disabled={session.viewOnly}
                    value={sessionNote}
                    onChange={updateSessionNote}
                    placeholder={
                        (session.viewOnly && 'Notes disabled') ||
                        'Type a note and hit enter'
                    }
                    id="SessionEvents-staff-notes"
                    className="Session-notes-input"
                    aria-label="Staff Notes"
                    data-testid={CY.SESSION_DETAILS.SESSION_EVENTS_STAFF_NOTES}
                />
            </form>
            <div className="row">
                <form name="ICADform" onSubmit={saveICAD}>
                    <div
                        onClick={toggleICAD}
                        className="cursor-pointer"
                        id="SessionEvents-ICAD-label"
                        data-testid={
                            CY.SESSION_DETAILS.SESSION_EVENTS_ICAD_LABEL
                        }
                    >
                        {showICAD ? <FaChevronDown /> : <FaChevronRight />}
                        <label htmlFor="ICAD" className="cursor-pointer">
                            ICAD #
                        </label>
                    </div>
                    {showICAD && (
                        <div>
                            <input
                                id="SessionEvents-ICAD-input"
                                name="ICAD"
                                type="text"
                                disabled={session.viewOnly}
                                value={sessionICAD}
                                onChange={handleICADChange}
                                data-testid={
                                    CY.SESSION_DETAILS.SESSION_EVENTS_ICAD_INPUT
                                }
                            />
                            <button
                                disabled={
                                    !sessionICAD || session.ICAD === sessionICAD
                                }
                                className="button light-blue"
                                type="submit"
                                data-testid={
                                    CY.SESSION_DETAILS.SESSION_ICAD_SAVE_BUTTON
                                }
                            >
                                Save
                            </button>
                            &nbsp;
                            {savingICAD && (
                                <FaCheckCircle
                                    className="saving-check"
                                    id="SessionEents-ICAD-check"
                                />
                            )}
                        </div>
                    )}
                </form>
            </div>
            <div className="SessionEvents-event-list">
                {map(filteredEvents, renderEvent)}
            </div>
        </div>
    );
}

import { includes, replace } from 'lodash';

export function tinyMCEConfig(overrides) {
    const DEFAULTS = {
        placeholder: 'Type here...',
        height: 250,
        // width: '100%',
        menubar: false,
        plugins: [
            'autolink', 'lists', 'advlist', 'searchreplace', 'paste', 'table', 'link', 'autolink', 'hr'
        ],
        toolbar:
            'blocks |  bold italic underline | numlist bullist | link paste | hr',
        paste_preprocess: function (plugin, args) {
            // We will do our own pasting processing - we allow only certain HTML tags below
            args.content = stripTags(
                args.content,
                'b',
                'i',
                'u',
                'p',
                'br',
                'a',
                'strong',
                'em'
            );
        },
        extended_valid_elements: 'span',
        default_link_target: '_blank',
        browser_spellcheck: true,
        contextmenu: false
    };
    return {
        ...DEFAULTS,
        ...overrides
    };
}

const stripTags = (html, ...args) =>
    replace(
        replace(
            html,
            /<(\/?)(\w+) ([^>]*)[^>]*\/?>/g,
            (_, endMark, tag, attributes) =>
                includes(args, tag)
                    ? '<' +
                      endMark +
                      tag +
                      (endMark ? '' : ' ' + attributes) +
                      '>'
                    : ''
        ),
        /<!--.*?-->/g,
        ''
    );

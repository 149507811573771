import { map } from 'lodash';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setSelectedStep } from '../../redux/actions';
import { getSelectedStep } from '../../redux/selectors';

export default function StepPicker({ steps }) {
    // Redux
    const selectedStep = useSelector(getSelectedStep);
    const dispatch = useDispatch();

    return (
        <ul id="ActionPlan-step-picker">
            {map(steps, (step) => (
                <li
                    key={step.name}
                    className="ActionPlanStepForm-step"
                    style={
                        selectedStep?.name === step.name
                            ? { backgroundColor: '#eee' }
                            : {}
                    }
                    onClick={() => dispatch(setSelectedStep(step))}
                >
                    {step.title}
                </li>
            ))}
        </ul>
    );
}

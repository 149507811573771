import '../styling/Header.css';

import { isEmpty } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import CY from '../config/cypressConstants';
import useSocket from '../hooks/useSocket';
import { getUser } from '../redux/selectors';

export default function Header({ logOut }) {
    const socket = useSocket();
    const user = useSelector(getUser);

    function activeLinkStyle({ isActive }) {
        return isActive
            ? {
                  color: '#79BE38'
              }
            : undefined;
    }

    function handleLogout(e) {
        e.preventDefault();
        logOut();
    }

    return (
        <header className="MC-header">
            <div className="Header-nav-container">
                <h1>
                    <strong>Monitoring</strong>Center
                </h1>
                {socket && (
                    <ul className="Header-nav">
                        <li>
                            <NavLink
                                to="/"
                                title="Monitor"
                                style={activeLinkStyle}
                            >
                                Monitor
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to="/dashboard"
                                title="Dashboard"
                                style={activeLinkStyle}
                            >
                                Dashboard
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to="/log"
                                title="Log"
                                style={activeLinkStyle}
                            >
                                Log
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to="/action-plans"
                                title="Action Plans"
                                style={activeLinkStyle}
                            >
                                Action Plans
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to="/psap-map"
                                title="PSAP Map"
                                style={activeLinkStyle}
                            >
                                PSAP Map
                            </NavLink>
                        </li>
                    </ul>
                )}
            </div>
            {!isEmpty(user) && (
                <p>
                    Logged in as {user.first_name} {user.last_name}{' '}
                    <a
                        href="#"
                        id="logout"
                        onClick={handleLogout}
                        data-testid={CY.BUTTONS.LOGOUT_BUTTON}
                    >
                        (Log Out)
                    </a>
                </p>
            )}
        </header>
    );
}

import '../../styling/Dashboard.css';

import { get, map, reduce } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { FaSpinner } from 'react-icons/fa';
import { useSelector } from 'react-redux';

import {
    DASHBOARD_STATUS_OPTIONS,
    DASHBOARD_STAT_TYPES,
    STATES
} from '../../config/constants';
import CY from '../../config/cypressConstants';
import useSocket from '../../hooks/useSocket';
import {
    getGroupedSessions,
    getSessions,
    getSessionsLoading
} from '../../redux/selectors';

import DailyTotals from './DailyTotals';
import RecentAlarms from './RecentAlarms';

export default function Dashboard() {
    // State
    const [stats, setStats] = useState(null);
    const [alarms, setAlarms] = useState([]);
    const [dashStatus, setDashStatus] = useState(
        DASHBOARD_STATUS_OPTIONS.ok.msg
    );
    const [uiStatus, setUiStatus] = useState(DASHBOARD_STATUS_OPTIONS.ok.class);

    // Refs
    const alarmsRef = useRef();
    alarmsRef.current = alarms;

    // Redux
    const sessionsLoading = useSelector(getSessionsLoading);
    const sessions = useSelector(getSessions);
    const groupedSessions = useSelector(getGroupedSessions);

    // Hooks
    useSocket({
        listeners: [
            ['stats', (data) => setStats(data)],
            ['alarms', (data) => setAlarms(data)]
        ],
        emits: [['getStats']]
    });

    useEffect(() => {
        if (_getSessionGroupLength(STATES.alarm) > 0) {
            setDashStatus(DASHBOARD_STATUS_OPTIONS.alarm.msg);
            setUiStatus(DASHBOARD_STATUS_OPTIONS.alarm.class);
        } else if (_getSessionGroupLength(STATES.alarmTimerStarted) > 0) {
            setDashStatus(DASHBOARD_STATUS_OPTIONS.tether.msg);
            setUiStatus(DASHBOARD_STATUS_OPTIONS.tether.class);
        } else {
            setDashStatus(DASHBOARD_STATUS_OPTIONS.ok.msg);
            setUiStatus(DASHBOARD_STATUS_OPTIONS.ok.class);
        }
    }, [groupedSessions]);

    function _getSessionGroupLength(key) {
        return get(groupedSessions, key, []).length;
    }

    function getTotalByType(type) {
        let finalTotal = _getSessionGroupLength(type.key);
        if (type.additionalKeys) {
            finalTotal = reduce(
                type.additionalKeys,
                (total, key) => total + _getSessionGroupLength(key),
                finalTotal
            );
        }
        return finalTotal;
    }

    return (
        <div className="Dashboard">
            <div className="Dashboard-stats">
                {sessionsLoading && (
                    <div className="LargeSpinner">
                        <FaSpinner className="spinner" />
                    </div>
                )}
                {!sessionsLoading && (
                    <>
                        <div
                            className={`Dashboard-stats-header ${uiStatus}-background`}
                        >
                            <h2>{dashStatus}</h2>
                            <h3>
                                CURRENT ACTIVE SESSIONS:{' '}
                                {get(sessions, 'length', 0)}
                            </h3>
                        </div>
                        <dl className="Dashboard-stats-body">
                            {map(DASHBOARD_STAT_TYPES, (type, ix) => (
                                <div
                                    key={`${type.key}-${ix}`}
                                    className="Dashboard-stats-cell"
                                    data-testid={`${CY.DASHBOARD_PAGE.DASHBOARD_STAT_CELL}-${type.state}`}
                                >
                                    <dt className={`${type.state}-text`}>
                                        {type.title}
                                    </dt>
                                    <dd>{getTotalByType(type)}</dd>
                                </div>
                            ))}
                        </dl>
                    </>
                )}
            </div>
            <div className="flex">
                <DailyTotals stats={stats} />
                <RecentAlarms alarms={alarms} />
            </div>
        </div>
    );
}

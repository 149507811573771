import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { findIndex, isNaN, trim } from 'lodash';
import { toast } from 'react-toastify';

import dingSound from './assets/sounds/Airplane-ding.mp3';
import tetherSound from './assets/sounds/tetherPulled.mp3';
import untendedSound from './assets/sounds/untendedAlarm-fixed.mp3';

export function formatPhone(phone, country = 'US') {
    if (!phone) {
        return phone;
    }
    // check if the phone number is masked by seeing if the first value is *
    if (phone.charAt(0) === '*') {
        return phone;
    }

    const phoneNumber = parsePhoneNumberFromString(phone, country);

    if (phoneNumber) {
        if (phoneNumber.country === 'US') {
            // NOTE: returns '(213) 373-4253'
            return phoneNumber.formatNational();
        } else {
            // NOTE: returns '+1 213 373 4253'
            return phoneNumber.formatInternational();
        }
    }
}

export function truncateString(value, wordWise, max = '0', tail = ' …') {
    value = trim(value);
    if (!value) {
        return '';
    }

    let bound = parseInt(max);
    if (!bound || isNaN(bound) || value.length <= bound) {
        return value;
    }

    value = value.substr(0, bound);
    let lastSpace = value.lastIndexOf(' ');
    if (wordWise && lastSpace >= 0) {
        value = value.substr(0, lastSpace);
    }

    return `${value}${tail}`;
}

export function replace(collection, updatedObj, pathToKey = 'id') {
    const ix = findIndex(
        collection,
        (obj) => obj[pathToKey] === updatedObj[pathToKey]
    );
    if (ix > -1) collection[ix] = updatedObj;
    return [...collection];
}

export function buildSessionId(session) {
    return session
        ? `${session.userId ? session.userId : session.user?.userId}|${
              session.startDate
          }`
        : null;
}

const sounds = {
    untended: new Audio(untendedSound),
    tether: new Audio(tetherSound),
    ding: new Audio(dingSound)
};
// Match the sound string passed in to the correct sound and play it
const playSound = async (sound, count = 1, loop = false) => {
    let soundAudio = sounds[sound];
    if (loop) {
        soundAudio.loop = loop;
    } else {
        soundAudio.onended = () =>
            --count ? soundAudio.play() : (soundAudio.onended = null);
    }
    try {
        await soundAudio.play();
    } catch (e) {
        toast.error(
            'Cannot play sounds yet. Please click on a session to activate sounds.'
        );
    }
};

export async function playDisconnected() {
    await playSound('ding', 2);
}
export async function playExtended() {
    await playSound('ding', 2);
}
export async function playPaused() {
    await playSound('ding', 2);
}
export async function playTetherPulled() {
    await playSound('tether', 2);
}
export async function playTimerExpiring() {
    await playSound('ding', 2);
}
export async function playUntended() {
    await playSound('untended', 1, true);
}
export function stopUntended() {
    sounds.untended.loop = false;
}

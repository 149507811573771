import '../../../styling/SessionDetails.css';
import '../../../styling/Session-shared.css';

import { filter, isEmpty, sortBy, toUpper } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { FaSpinner, FaTimes } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';

import { ACTIONS, STATES, STATES_DISPLAY } from '../../../config/constants';
import useSocket from '../../../hooks/useSocket';
import { setSessionToView, setSessionToViewId } from '../../../redux/actions';
import {
    getSessionToView,
    getSessionToViewId,
    getUser
} from '../../../redux/selectors';
import StickyNoteEditor from '../../shared/StickyNoteEditor';

import ActionPlan from './ActionPlan';
import SessionActions from './SessionActions';
import SessionEvents from './SessionEvents';
import SessionLocations from './SessionLocations';
import UserInfo from './UserInfo';

export default function SessionDetails() {
    // State
    const [loading, setLoading] = useState(true);
    const [events, setEvents] = useState(null);
    const [startLocation, setStartLocation] = useState(null);
    const [currentLocation, setCurrentLocation] = useState(null);
    const [breadcrumbLocations, setBreadcrumbLocations] = useState(null);

    // Redux
    const dispatch = useDispatch();
    const session = useSelector(getSessionToView);
    const sessionId = useSelector(getSessionToViewId);
    const user = useSelector(getUser);

    // Refs
    const sessionRef = useRef();
    sessionRef.current = session;

    // Hooks
    const socket = useSocket({
        listeners: [
            [
                'sessionDetails',
                (sessionDetails) => {
                    dispatch(setSessionToView(sessionDetails));
                    setLoading(false);
                }
            ],
            [
                'sessionEvents',
                (events) => {
                    // We need to make sure the events coming in are for the session currently being viewed;
                    //  they could be part of a global update
                    // TODO: update this when sessionId is modified
                    if (
                        events[0]?.sessionId ===
                        `${sessionRef?.current?.user?.userId}|${sessionRef?.current?.startDate}`
                    ) {
                        setEvents(events);
                    }
                }
            ],
            [
                'reverseGeocodeResults',
                (results) => {
                    // TODO: update this when sessionId is modified
                    if (
                        results.sessionId ===
                        `${sessionId.userId}|${sessionId.startDate}`
                    ) {
                        setCurrentLocation(results);
                    }
                }
            ]
        ],
        emits: [['getSessionDetails', sessionId]]
    });

    useEffect(() => {
        // Whenever the session changes, re-fetch events
        if (!isEmpty(session)) {
            // TODO: update this when sessionId is modified
            socket.emit('getSessionEvents', sessionId);
        }
    }, [session]);

    useEffect(() => {
        if (!isEmpty(events)) {
            _processLocationEvents(
                filter(events, ['action', ACTIONS.location])
            );
        }
    }, [events]);

    function close() {
        dispatch(setSessionToViewId(null));
    }

    function _processLocationEvents(locationEvents) {
        locationEvents = sortBy(locationEvents, ['date']);
        setStartLocation(locationEvents.shift());
        const current = locationEvents.pop();
        if (current?.date === currentLocation?.date) {
            // We already have the latest location, so don't bother setting it again
            return;
        }
        if (current && !current.address) {
            // This current location has not been reverse geocoded yet
            socket.emit('reverseGeocode', current);
        } else {
            setCurrentLocation(current);
        }
        setBreadcrumbLocations(locationEvents);
    }

    return (
        <div className="SessionDetails-container">
            <div className="SessionDetails-overlay" onClick={close}></div>
            <div className="SessionDetails-holder">
                {loading && (
                    <div className="LargeSpinner">
                        <FaSpinner className="spinner" />
                    </div>
                )}
                {!loading && !session && (
                    <p className="SessionDetails-empty">
                        Unable to retrieve details for this session.{' '}
                        <a
                            href="#"
                            onClick={(e) => {
                                e.preventDefault();
                                close();
                            }}
                        >
                            Close.
                        </a>
                    </p>
                )}
                {!loading && session && (
                    <div>
                        <div
                            className={`SessionDetails-header ${session.state}-background`}
                        >
                            <h2>
                                {session.user.firstName} {session.user.lastName}{' '}
                                - {session.user.customer.name}{' '}
                                {toUpper(STATES_DISPLAY[session.state])}
                                {session.staffAssignee &&
                                    ` (Managed by: ${session.staffAssignee.firstName} ${session.staffAssignee.lastName})`}
                            </h2>
                            <FaTimes
                                className="SessionDetails-close"
                                onClick={close}
                            />
                        </div>
                        <div className="SessionDetails-body">
                            <div className="column">
                                <UserInfo session={session} />
                                <SessionLocations
                                    session={session}
                                    startLocation={startLocation}
                                    currentLocation={currentLocation}
                                    breadcrumbLocations={breadcrumbLocations}
                                />
                            </div>
                            <div className="column">
                                {/* Action Buttons and Action Plan */}
                                {session.emergencyDispatch && (
                                    <p
                                        className="SessionDetails-section"
                                        id="EmergencyDispatch-panel"
                                    >
                                        EMERGENCY DISPATCH
                                    </p>
                                )}
                                {session.state !== STATES.sessionEnded && (
                                    <SessionActions session={session} />
                                )}
                                {session.actionPlan &&
                                    (session.state === STATES.sessionEnded ||
                                        session.staffAssignee) && (
                                        <ActionPlan
                                            session={session}
                                            events={events || []}
                                        />
                                    )}

                                {/* Customer Notes */}
                                <StickyNoteEditor
                                    customStyle={{ backgroundColor: '#ffffe0' }}
                                    initialText={session?.user.customer.notes}
                                    onSave={(text) =>
                                        socket.emit('addCustomerNotes', {
                                            customerId:
                                                session?.user.customer
                                                    .customerId,
                                            notes: text
                                        })
                                    }
                                    pageTitle={`${session?.user.customer.name} Notes`}
                                    placeholder="Enter a customer note. Click outside the form when you're finished to save."
                                    userCanEdit={user.isMCAdmin}
                                />
                                {/* Incident Summary */}
                                {session.endDate && (
                                    <StickyNoteEditor
                                        customStyle={{
                                            backgroundColor: 'lightcyan'
                                        }}
                                        initialText={session.summary}
                                        onSave={(text) =>
                                            // TODO: update this when sessionId is modified
                                            socket.emit('addSessionSummary', {
                                                summary: text,
                                                userId: session.userId,
                                                startDate: session.startDate
                                            })
                                        }
                                        pageTitle="Incident Summary"
                                        placeholder="Provide a brief summary of the incident"
                                        userCanEdit={user.isMCAdmin}
                                    />
                                )}
                                <SessionEvents
                                    session={session}
                                    events={events}
                                />
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

import { find, map, omit } from 'lodash';
import React, { useEffect, useState } from 'react';

import greenCheck from '../../../assets/images/ic_check.png';
import { ACTIONS, ACTORS } from '../../../config/constants';
import CY from '../../../config/cypressConstants';
import useSocket from '../../../hooks/useSocket';

export default function ActionPlan({ session, events }) {
    // State
    const [steps, setSteps] = useState(session?.actionPlan?.steps);
    const [expandedStepIx, setExpandedStepIx] = useState(0);

    // Hooks
    const socket = useSocket();

    // Loop through the steps and check if they are completed,
    // when we find the first that is not set the expandedStepIx to that index
    useEffect(() => {
        for (let i = 0; i < steps.length; i++) {
            if (!_stepCompleted(steps[i])) {
                setExpandedStepIx(i);
                break;
            }
        }
    }, [events, steps]);

    useEffect(() => {
        setSteps(session?.actionPlan?.steps);
    }, [session]);

    function completeStep(step) {
        // TODO: conditionally add note

        // Emit socket event to complete step
        const parsedSession = {
            ...session,
            // TODO: why is this necessary?
            events: []
        };

        const message = {
            session: parsedSession,
            staffAction: session.staffAction
        };

        if (step) {
            parsedSession.actionPlan.steps = map(
                parsedSession.actionPlan.steps,
                (step) => omit(step, 'isOpen')
            );
            message.step = step;
        }

        socket.emit('completeStep', message);
    }

    function openCloseStep(ix) {
        // If the section the user clicked is already open, toggle it closed.
        //  Otherwise, open the step that was clicked.
        if (expandedStepIx === ix) {
            setExpandedStepIx(null);
        } else {
            setExpandedStepIx(ix);
        }
    }

    function _stepCompleted(step) {
        return (
            step.completed ||
            !!find(events, {
                action: ACTIONS.actionPlanStepCompleted,
                step: { name: step.name },
                actionPlanName: session.actionPlan.name
            })
        );
    }

    return (
        <div className="SessionDetails-section">
            <h3>{session.actionPlan.title}</h3>

            {map(steps, (step, ix) => (
                <div key={step.name}>
                    <div
                        className={`ActionStep-header ${
                            _stepCompleted(step) &&
                            'ActionStep-header-completed'
                        }`}
                        onClick={() => openCloseStep(ix)}
                    >
                        {_stepCompleted(step) && (
                            <img src={greenCheck} alt="Green checkmark" />
                        )}
                        <h4>{step.title}</h4>
                    </div>
                    {step.actor !== ACTORS.manualInput &&
                        ix === expandedStepIx && (
                            <div className="ActionStep-body">
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: step.detail
                                    }}
                                />

                                {step.actor === ACTORS.manual &&
                                    !_stepCompleted(step) && (
                                        <button
                                            id="markAsDoneButton"
                                            className="button dark-blue"
                                            type="button"
                                            onClick={() => completeStep(step)}
                                            disabled={session.viewOnly}
                                            data-testid={
                                                CY.SESSION_DETAILS
                                                    .SESSION_DONE_BUTTON
                                            }
                                        >
                                            Mark as Done!
                                        </button>
                                    )}
                            </div>
                        )}
                </div>
            ))}
        </div>
    );
}

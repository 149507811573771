import { Editor } from '@tinymce/tinymce-react';
import { cloneDeep, includes, isEmpty, map, replace, trim } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import {
    ACTION_PLAN_SYSTEM_STRING,
    ACTORS,
    ACTORS_DISPLAY,
    NOTIFICATION_FORMATS,
    NOTIFICATION_FORMATS_DISPLAY
} from '../../config/constants';
import CY from '../../config/cypressConstants';
import { tinyMCEConfig } from '../../config/tinyMCE';
import useFormValues from '../../hooks/useFormValues';
import useSocket from '../../hooks/useSocket';
import {
    getSelectedCustomerId,
    getSelectedStep,
    getUser
} from '../../redux/selectors';

export default function StepForm() {
    // Redux
    const customerId = useSelector(getSelectedCustomerId);
    const step = useSelector(getSelectedStep);
    const user = useSelector(getUser);

    // State
    const [formValues, setFormValues, setOneFormValue] = useFormValues(null);
    const [isSystemStep, setIsSystemStep] = useState(false);
    const [editorKey, _setEditorKey] = useState(0); // Editor key is used to trigger a rerender of TinyMCE to clear the description on form submit

    // Refs
    const tinyMCERef = useRef(null);

    // Hooks
    const socket = useSocket();

    useEffect(() => {
        // Whenever the step changes, update the formValues (they always track the
        //  step that is currently being edited)
        if (!isEmpty(step)) {
            setFormValues({
                // We have to cloneDeep bc the step has nested arrays and objects
                ...cloneDeep(step),
                // We want to remove the [SYSTEM] string from the title bc users aren't allowed to edit it
                title: _removeSystemString(step.title)
            });
        }
    }, [step]);

    function _removeSystemString(title) {
        const systemStringMatch = includes(title, ACTION_PLAN_SYSTEM_STRING);

        // Need to track this so we can add the system string back in before saving
        setIsSystemStep(systemStringMatch);

        if (systemStringMatch) {
            title = trim(replace(title, ACTION_PLAN_SYSTEM_STRING, ''));
        }
        return title;
    }

    function _putBackSystemString(title) {
        if (isSystemStep) {
            title = `${ACTION_PLAN_SYSTEM_STRING} ${title}`;
        }
        return title;
    }

    function handleEditorChange(fieldName, text) {
        setOneFormValue(fieldName, text);
    }

    function handleTitleChange(e) {
        setOneFormValue('title', e.target.value);
    }

    function handleSubmit(e) {
        e.preventDefault();
        socket.emit('saveActionPlanStep', {
            customerId,
            originalValues: step,
            updatedValues: {
                ...formValues,
                title: _putBackSystemString(formValues.title)
            },
            user
        });
    }

    function renderStepForm() {
        if (isEmpty(step) || isEmpty(formValues)) return null;
        switch (step.actor) {
            case ACTORS.psap:
                return (
                    <div className="StepForm-input-container">
                        <p>{step.detail}</p>
                    </div>
                );
            case ACTORS.notifier:
                return (
                    <div>
                        <div className="StepForm-input-container">
                            <h4>Recipients:</h4>
                            <ul id="StepFrom-recipients">
                                {step.groupsDisplay && (
                                    <li>{step.groupsDisplay}</li>
                                )}
                                {step.usersDisplay && (
                                    <li>{step.usersDisplay}</li>
                                )}

                                <li>
                                    <label htmlFor="EmergencyContacts">
                                        Emergency Contacts
                                    </label>{' '}
                                    <input
                                        id="EmergencyContacts"
                                        type="checkbox"
                                        disabled={!user.isMCAdmin}
                                        checked={
                                            formValues.notifyEmergencyContacts ||
                                            false
                                        }
                                        onChange={(e) =>
                                            setOneFormValue(
                                                'notifyEmergencyContacts',
                                                e.target.checked
                                            )
                                        }
                                    />
                                </li>

                                <li>
                                    <label htmlFor="NotifyUser">
                                        Session User
                                    </label>{' '}
                                    <input
                                        id="NotifyUser"
                                        type="checkbox"
                                        disabled={!user.isMCAdmin}
                                        checked={formValues.notifyUser || false}
                                        onChange={(e) =>
                                            setOneFormValue(
                                                'notifyUser',
                                                e.target.checked
                                            )
                                        }
                                    />
                                </li>
                            </ul>
                        </div>
                        {map(step.messages, (message, ix) => (
                            <div
                                className="StepForm-input-container"
                                key={`${message.format}-${ix}`}
                            >
                                <label htmlFor={`${message.format}-tinymce`}>
                                    {
                                        NOTIFICATION_FORMATS_DISPLAY[
                                            message.format
                                        ]
                                    }
                                    :
                                </label>
                                <div className="TinyMCE-container">
                                    <Editor
                                        key={editorKey}
                                        id={`${message.format}-tinymce`}
                                        disabled={!user.isMCAdmin}
                                        initialValue={message.message}
                                        content={message.message}
                                        tinymceScriptSrc={'/tinymce/tinymce.min.js'}
                                        init={tinyMCEConfig({
                                            height:
                                                message.format ===
                                                NOTIFICATION_FORMATS.email
                                                    ? '750px'
                                                    : '200px',
                                            placeholder: 'Type details here'
                                        })}
                                        onInit={(event, editor) =>
                                            (tinyMCERef.current = editor)
                                        }
                                        onEditorChange={(text) =>
                                            handleEditorChange(
                                                `messages[${ix}].message`,
                                                text
                                            )
                                        }
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                );
            case ACTORS.manual:
                return (
                    <div className="StepForm-input-container">
                        <label htmlFor="description-tinymce">Message:</label>
                        <div className="TinyMCE-container">
                            <Editor
                                disabled={!user.isMCAdmin}
                                key={editorKey}
                                id="description-tinymce"
                                initialValue={step?.detail}
                                content={formValues.detail || null}
                                tinymceScriptSrc={'/tinymce/tinymce.min.js'}
                                init={tinyMCEConfig({
                                    placeholder: 'Type details here',
                                    height: '500px'
                                })}
                                onInit={(event, editor) =>
                                    (tinyMCERef.current = editor)
                                }
                                onEditorChange={(text) =>
                                    handleEditorChange('detail', text)
                                }
                            />
                        </div>
                    </div>
                );
            default:
                return null;
        }
    }

    if (isEmpty(step)) return null;

    return (
        <form className="StepForm" onSubmit={handleSubmit}>
            <div className="StepForm-input-container">
                <label htmlFor="StepName">Step Name:</label>
                {isSystemStep && (
                    <span className="StepName-system-string">
                        {ACTION_PLAN_SYSTEM_STRING}
                    </span>
                )}
                <input
                    id="StepName"
                    className="ActionPlanEditor-input"
                    type="text"
                    disabled={!user.isMCAdmin}
                    value={formValues?.title || ''}
                    onChange={handleTitleChange}
                    data-testid={CY.ACTION_PLANS_PAGE.STEP_INPUT}
                />
            </div>
            <div className="StepForm-input-container">
                <label>Step Type:</label>
                <span>{ACTORS_DISPLAY[step.actor]}</span>
            </div>
            {renderStepForm()}

            {user.isMCAdmin && (
                <input
                    type="submit"
                    value="Save Step"
                    className="button dark-blue"
                    style={{ float: 'right' }}
                    data-testid={CY.ACTION_PLANS_PAGE.SAVE_STEP_BUTTON}
                />
            )}
        </form>
    );
}

import '../../../styling/Session-shared.css';
import '../../../styling/UserInfo.css';

import dayjs from 'dayjs';
import { compact, find, get, includes, map, pickBy } from 'lodash';
import React, { useState } from 'react';

import genericProfileImg from '../../../assets/images/generic-profile.png';
import {
    ACTIONS_DISPLAY,
    SESSION_TYPES_DISPLAY,
    USER_BIOGRAPHICAL_FIELDS
} from '../../../config/constants';
import CY from '../../../config/cypressConstants';
import { formatPhone } from '../../../utils';

export default function UserInfo({ session }) {
    const [showAdditionalData, setShowAdditionalData] = useState(false);

    const sessionUser = session.user;
    const supervisor = _getSessionSupervisor();
    const device = session.device;
    function _getSessionSupervisor() {
        let supervisor;
        if (
            session.isHighRiskUser &&
            session.user.customer.highRiskSupervisor
        ) {
            supervisor = session.user.customer.highRiskSupervisor;
        } else {
            let ecPriority =
                session.user.customer.primary_emergency_contact_priority || 0;
            supervisor = find(session.user.emergencyContacts, [
                'priority',
                ecPriority
            ]);
        }
        if (supervisor !== undefined) {
            supervisor.emails = compact([
                supervisor.email,
                supervisor.email2,
                supervisor.email3
            ]);
            supervisor.phones = compact([
                supervisor.mobilePhone,
                supervisor.mobilePhone2,
                supervisor.mobilePhone3
            ]);
        }
        return supervisor;
    }

    function toggleAdditionalData() {
        setShowAdditionalData((currVal) => !currVal);
    }

    const customData = pickBy(
        sessionUser.additionalData,
        (value, field) =>
            !includes(
                map(USER_BIOGRAPHICAL_FIELDS, ({ key }) => key),
                field
            )
    );

    return (
        <div className="UserInfo-container SessionDetails-section">
            <div
                className="UserInfo-image"
                data-testid={CY.SESSION_DETAILS.SESSION_USER_INFO_IMAGE}
            >
                <img
                    className="UserInfo-avatar"
                    src={
                        sessionUser.photoUrl
                            ? sessionUser.photoUrl
                            : genericProfileImg
                    }
                />
                {session.isHighRiskUser && (
                    <span className="Session-high-risk">HIGH RISK</span>
                )}
                {device && (
                    <div
                        className={'UserDeviceInfo-container'}
                        data-testid={
                            CY.SESSION_DETAILS.SESSION_USER_DEVICE_INFO
                        }
                    >
                        <div className="UserDeviceInfo-row">
                            <dt>App Version:</dt>
                            <dd> {device?.app_version}</dd>
                        </div>
                        <div className="UserDeviceInfo-row">
                            <dt>Device OS:</dt>
                            <dd> {device?.device_os}</dd>
                        </div>
                        <div className="UserDeviceInfo-row">
                            <dt>Device OS version:</dt>
                            <dd> {device?.device_os_version}</dd>
                        </div>
                        <div className="UserDeviceInfo-row">
                            <dt>Battery Status:</dt>
                            <dd> {device?.battery_level}</dd>
                        </div>
                        <div className="UserDeviceInfo-row">
                            <dt>Location Permissions: </dt>
                            <dd>
                                {device?.location_services ? (
                                    <div>Enabled</div>
                                ) : (
                                    <div>Disabled</div>
                                )}
                            </dd>
                        </div>
                    </div>
                )}
            </div>
            <div className="UserInfo-body">
                {session.editable && !session.viewOnly ? (
                    <div className="user-full-name">
                        {/* TODO: clean this up and make it actually functional
                        <input
                            class="user-input"
                            type="text"
                            ng-model="sessionUser.firstName"
                            ng-focus="stashUserName('firstName')"
                            ng-blur="updateUserName('firstName')"
                        />
                        &nbsp;
                        <input
                            class="user-input"
                            type="text"
                            ng-model="sessionUser.lastName"
                            ng-focus="stashUserName('lastName')"
                            ng-blur="updateUserName('lastName')"
                        />
                        <i
                            class="fa fa-check-circle saving-check"
                            ng-show="saving.user"
                        ></i>*/}
                    </div>
                ) : (
                    <h3
                        className="UserInfo-header"
                        data-testid={
                            CY.SESSION_DETAILS.SESSION_USER_INFO_HEADER
                        }
                    >
                        {sessionUser.firstName} {sessionUser.lastName}
                    </h3>
                )}
                <dl>
                    {/* TODO: parse telephone numbers */}
                    <div className="row">
                        <dt>Mobile #:</dt>
                        <dd>{formatPhone(sessionUser.mobilePhone)}</dd>
                        {sessionUser.mobilePhone &&
                            sessionUser.mobilePhone2 && (
                                <dd>
                                    , {formatPhone(sessionUser.mobilePhone2)}
                                </dd>
                            )}
                    </div>
                    <div className="row">
                        <dt>Email:</dt>
                        <dd>{sessionUser.email}</dd>
                    </div>
                    {sessionUser.email2 && (
                        <div className="row">
                            <dt>Email 2:</dt>
                            <dd>{sessionUser.email2}</dd>
                        </div>
                    )}
                    <hr />
                    <div>
                        <div className="UserInfo-biographical">
                            {map(USER_BIOGRAPHICAL_FIELDS, ({ key, label }) => (
                                <div key={key}>
                                    <dt>{label}:</dt>
                                    <dd>
                                        {get(
                                            sessionUser,
                                            `additionalData[${key}]`
                                        )}
                                    </dd>
                                </div>
                            ))}
                        </div>
                        <div className="row">
                            (
                            <span
                                className="UserInfo-additional-data-toggle"
                                onClick={toggleAdditionalData}
                            >
                                {showAdditionalData ? 'hide' : 'show'} custom
                                data
                            </span>
                            )
                        </div>
                        {showAdditionalData && (
                            <div className="UserInfo-biographical">
                                {map(customData, (val, key) => (
                                    <div key={key}>
                                        <dt>{key}:</dt>
                                        <dd>{JSON.stringify(val)}</dd>
                                    </div>
                                ))}
                            </div>
                        )}
                        <hr />
                        <div className="row">
                            <div>
                                <dt>User Entered Address:</dt>{' '}
                                <dd>{session.address}</dd>
                            </div>
                        </div>
                        <hr />
                    </div>
                    {get(sessionUser, 'device.app_name') && (
                        <div className="row">
                            <dt>Mobile App Source:</dt>{' '}
                            <dd>{sessionUser.device.app_name}</dd>
                        </div>
                    )}
                    <div className="row">
                        <dt>Session Type:</dt>
                        <dd>
                            {SESSION_TYPES_DISPLAY[session.sessionType] ||
                                SESSION_TYPES_DISPLAY.tether}
                        </dd>
                    </div>
                    <div className="row">
                        <dt>Session Start:</dt>{' '}
                        <dd>
                            {dayjs(session.startDate).format(
                                'M/D/YY h:mm:ss A'
                            )}
                        </dd>
                    </div>

                    <div className="row">
                        <dt>Last Heartbeat:</dt>
                        <dd>
                            {dayjs(session.heartbeat).format(
                                'M/D/YY h:mm:ss A'
                            )}
                        </dd>
                    </div>
                    <div className="row">
                        <dt>Last Action:</dt>
                        <dd className={`${session.state}-text`}>
                            {ACTIONS_DISPLAY[session.action]}
                        </dd>
                    </div>
                    {/* TODO: are notes actually still a thing? */}
                    {session.notes && (
                        <div className="row">
                            <dt>Session Notes:</dt>
                            <dd>{session.notes}</dd>
                        </div>
                    )}
                    {supervisor && (
                        <div className="UserInfo-emergency-contact">
                            <hr />
                            <div>
                                <div className="row">
                                    <dt>Supervisor Info</dt>
                                </div>
                                <div className="row">
                                    <dd>
                                        {supervisor.firstName}{' '}
                                        {supervisor.lastName}
                                    </dd>
                                </div>
                                {map(supervisor.phones, (phone) => (
                                    <div
                                        className="row"
                                        key={`${session.user.user_id}-${phone}`}
                                    >
                                        <dd>{formatPhone(phone)}</dd>
                                    </div>
                                ))}
                                {map(supervisor.emails, (email) => (
                                    <div
                                        className="row"
                                        key={`${session.user.user_id}-${email}`}
                                    >
                                        <dd>{email}</dd>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                    {session.psap && (
                        <div className="UserInfo-emergency-contact">
                            <hr />
                            <div className="row">
                                <dt>PSAP Info</dt>
                            </div>
                            <div className="row">
                                <dd>{session.psap.agency}</dd>
                            </div>
                            <div className="row">
                                <dd>{formatPhone(session.psap.phone)}</dd>
                            </div>
                        </div>
                    )}
                </dl>
            </div>
        </div>
    );
}

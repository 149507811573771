import '../../styling/StickyNoteEditor.css';
import '../../styling/Session-shared.css';

import React, { useState } from 'react';
import { FaCheckCircle, FaPencilAlt } from 'react-icons/fa';

import CY from '../../config/cypressConstants';

export default function StickyNoteEditor({
    initialText = '',
    onSave,
    pageTitle,
    customStyle = {},
    placeholder = 'Enter some text. Click outside the editor when you are finished to save.',
    userCanEdit = false
}) {
    // State
    const [editingText, setEditingText] = useState(false);
    const [textToEdit, setTextToEdit] = useState(initialText);
    const [savingText, setSavingText] = useState(false);

    // If the user can't edit and there is no text to edit, then there's nothing to display
    if (!userCanEdit && !textToEdit) return null;

    function updateText(e) {
        setTextToEdit(e.target.value);
    }

    function saveText() {
        setSavingText(true);
        onSave(textToEdit);
        setTimeout(() => {
            setSavingText(false);
            setEditingText(false);
        }, 1000);
    }

    function renderIcon() {
        if (!userCanEdit) return;
        let content;
        if (savingText) {
            content = <FaCheckCircle className="saving-check" />;
        }
        if (!editingText) {
            content = <FaPencilAlt onClick={() => setEditingText(true)} />;
        }
        if (content) {
            return (
                <span
                    className="StickyNote-icon"
                    data-testid={
                        CY.SESSION_DETAILS.SESSION_STICKY_NOTE_PENCIL_ICON
                    }
                >
                    {content}
                </span>
            );
        }
    }

    return (
        <div className="SessionDetails-section" style={customStyle}>
            <div className="StickyNote-header">
                <h3
                    id="StickyNote-title"
                    data-testid={CY.SESSION_DETAILS.SESSION_STICKY_NOTE_TITLE}
                >
                    {pageTitle}
                </h3>
                {renderIcon()}
            </div>
            <div
                className="StickyNote-body"
                data-testid={CY.SESSION_DETAILS.SESSION_STICKY_NOTE_BODY}
            >
                {editingText && userCanEdit ? (
                    <textarea
                        id="StickyNote-input"
                        aria-labelledby="StickyNote-header"
                        className="Session-notes-input"
                        onBlur={saveText}
                        value={textToEdit}
                        onChange={updateText}
                        placeholder={placeholder}
                    ></textarea>
                ) : (
                    <p>{textToEdit}</p>
                )}
            </div>
        </div>
    );
}

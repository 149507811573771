import dayjs from 'dayjs';
import { isEmpty, map } from 'lodash';
import React from 'react';

import { ACTIONS_DISPLAY } from '../../config/constants';
import CY from '../../config/cypressConstants';

export default ({ alarms = [] }) => (
    <div className="Dashboard-section" id="RecentAlarms-section">
        <div className="Dashboard-section-header">
            <h2>Recent Alarm Events</h2>
        </div>
        <div className="Dashboard-section-body">
            {/* TODO: the legacy app doesn't appear to display all alarms...figure out how it's filtering */}
            {isEmpty(alarms) ? (
                <p id="RecentAlarms-empty">NO RECENT ALARMS</p>
            ) : (
                <table
                    id="RecentAlarms-table"
                    data-testid={CY.DASHBOARD_PAGE.RECENT_ALARM_TABLE}
                >
                    <thead>
                        <tr>
                            <th>Time</th>
                            <th>Person</th>
                            <th>User Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {map(alarms, (alarm) => (
                            <tr key={`${alarm.yearMonth}-${alarm.updateTime}`}>
                                <td>
                                    <span className="Alarm-time">
                                        {dayjs(alarm.updateTime).format(
                                            'h:mm A'
                                        )}
                                    </span>
                                </td>
                                <td>
                                    {/* TODO: truncate this */}
                                    {alarm.firstName
                                        .concat(' ')
                                        .concat(alarm.lastName)}
                                </td>
                                <td
                                    className={`Alarm-action ${alarm.state}-text`}
                                >
                                    {ACTIONS_DISPLAY[alarm.action]}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </div>
    </div>
);

export const STATES = {
    active: 'active',
    alarm: 'alarm',
    alarmTimerStarted: 'alarmTimerStarted',
    expired: 'expired',
    heartbeatTimeout: 'heartbeatTimeout',
    paused: 'paused',
    sessionEnded: 'sessionEnded',
    timerExpiring: 'timerExpiring',
    timerExpiringSoon: 'timerExpiringSoon'
};

export const STATES_DISPLAY = {
    [STATES.active]: 'Active',
    [STATES.alarmTimerStarted]: 'Alarm Activated',
    [STATES.alarm]: 'Alarm Confirmed',
    [STATES.expired]: 'Extended',
    [STATES.heartbeatTimeout]: 'Lost Signal',
    [STATES.paused]: 'Session Paused',
    [STATES.sessionEnded]: 'Session Ended',
    [STATES.timerExpiring]: 'Timer Expiring',
    [STATES.timerExpiringSoon]: 'Timer Expiring Soon'
};

export const SESSION_GROUP_ORDER = [
    STATES.alarm,
    STATES.alarmTimerStarted,
    STATES.timerExpiringSoon,
    STATES.timerExpiring,
    STATES.heartbeatTimeout,
    STATES.expired,
    STATES.paused,
    STATES.sessionEnded,
    STATES.active
];

export const ACTIONS = {
    changeTimerEndDate: 'changeTimerEndDate',
    dispatchCompleted: 'dispatchCompleted',
    duress: 'duress',
    endedByStaff: 'endedByStaff',
    endedByUser: 'endedByUser',
    expiredSession: 'expiredSession',
    extendedRequest: 'extendedRequest',
    falseAlarm: 'falseAlarm',
    invalidCodeEntered: 'invalidCodeEntered',
    lostSignal: 'lostSignal',
    noResponse: 'noResponse',
    panicButtonPressed: 'panicButtonPressed',
    sessionPaused: 'sessionPaused',
    sessionResumed: 'sessionResumed',
    sessionStarted: 'sessionStarted',
    tetherPulled: 'tetherPulled',
    timerExpired: 'timerExpired',
    unAuthorizedSession: 'unAuthorizedSession',
    //event log only actions...
    actionPlanStepCompleted: 'actionPlanStepCompleted',
    heartbeat: 'heartbeat',
    location: 'location',
    staffAcknowledged: 'staffAcknowledged',
    staffExtendSession: 'staffExtendSession',
    staffNote: 'staffNote',
    updateStaffNote: 'updateStaffNote',
    userNote: 'userNote',
    staffEnded: 'staffEnded'
};

export const ACTIONS_DISPLAY = {
    [ACTIONS.actionPlanStepCompleted]: 'Action Plan Step Completed',
    [ACTIONS.changeTimerEndDate]: 'Timer Changed',
    [ACTIONS.dispatchCompleted]: 'Staff - Dispatched',
    [ACTIONS.duress]: 'Duress Code',
    [ACTIONS.endedByStaff]: 'Staff - Ended',
    [ACTIONS.endedByUser]: 'User - Completed',
    [ACTIONS.expiredSession]: 'Staff - Expired',
    [ACTIONS.extendedRequest]: 'User Requested Extension',
    [ACTIONS.falseAlarm]: 'Staff - False Alarm',
    [ACTIONS.heartbeat]: 'Heartbeat',
    [ACTIONS.invalidCodeEntered]: 'Invalid Code',
    [ACTIONS.location]: 'Location Change',
    [ACTIONS.lostSignal]: 'Staff - Lost Signal',
    [ACTIONS.noResponse]: 'User - No Response',
    [ACTIONS.panicButtonPressed]: 'Panic Button Pressed',
    [ACTIONS.sessionPaused]: 'Session Paused',
    [ACTIONS.sessionResumed]: 'Session Resumed',
    [ACTIONS.sessionStarted]: 'Session Started',
    [ACTIONS.staffAcknowledged]: 'Staff Acknowledged',
    [ACTIONS.staffExtendSession]: 'Session Extended',
    [ACTIONS.staffNote]: 'Note',
    [ACTIONS.updateStaffNote]: 'Note Updated',
    [ACTIONS.tetherPulled]: 'Tether Pulled',
    [ACTIONS.timerExpired]: 'Timer Expired',
    [ACTIONS.unAuthorizedSession]: 'Invalid Authorization',
    [ACTIONS.userNote]: 'User Note',
    [ACTIONS.staffEnded]: 'Staff Ended'
};

export const ACTORS = {
    manual: 'manual',
    notifier: 'notifier',
    psap: 'psap'
};

export const ACTORS_DISPLAY = {
    [ACTORS.manual]: 'Manual',
    [ACTORS.psap]: 'Gather PSAP',
    [ACTORS.notifier]: 'Send Notification'
};

export const ACTION_PLAN_SYSTEM_STRING = '[SYSTEM]';

export const SESSION_TYPES = {
    tether: 'tether',
    timer: 'timer',
    panic_button: 'panic_button'
};

export const SESSION_TYPES_DISPLAY = {
    [SESSION_TYPES.tether]: 'Tether',
    [SESSION_TYPES.timer]: 'Timer',
    [SESSION_TYPES.panic_button]: 'Panic Button'
};

export const USER_BIOGRAPHICAL_FIELDS = [
    { label: 'Gender', key: 'gender' },
    { label: 'Eyes', key: 'eye_color' },
    { label: 'Hair', key: 'hair_color' },
    { label: 'Height', key: 'height' },
    { label: 'Weight', key: 'weight' },
    { label: 'Car Model', key: 'car_model' },
    { label: 'Car Color', key: 'car_color' },
    { label: 'License Plate', key: 'car_license_plate' },
    { label: 'Birth City', key: 'birth_city' }
];

export const MAP_DEFAULTS = {
    bootstrapURLKeys: {
        key: 'AIzaSyBJ2V8HHauZYXjaM7_gkeotponxiyhk0iQ',
        v: '3',
        libraries: 'places,drawing,visualization'
    },
    defaultZoom: 15,
    maxZoom: 18,
    psapZoom: 7,
    options: {
        clickableIcons: true,
        controlSize: 25,
        fullscreenControl: true,
        mapTypeControl: true,
        streetViewControl: true,
        zoomControl: true,
        // This is necessary to display points of interest on the map
        styles: [
            {
                featureType: 'all',
                elementType: 'labels',
                stylers: [
                    {
                        visibility: '#on'
                    }
                ]
            }
        ]
    }
};

export const STAT_TYPES = {
    [ACTIONS.sessionStarted]: {
        title: 'Sessions',
        key: ACTIONS.sessionStarted,
        state: null
    },
    [STATES.alarm]: {
        title: 'Alarm Confirmed',
        key: STATES.alarm,
        state: STATES.alarm
    },
    [ACTIONS.duress]: {
        title: 'Duress Code',
        key: ACTIONS.duress,
        state: ACTIONS.duress
    },
    [STATES.alarmTimerStarted]: {
        title: 'Alarm Activated',
        key: STATES.alarmTimerStarted,
        state: STATES.alarmTimerStarted
    },
    [STATES.timerExpiring]: {
        title: 'Timer Expiring',
        key: STATES.timerExpiring,
        additionalKeys: [STATES.timerExpiringSoon],
        state: STATES.timerExpiring
    },
    [STATES.heartbeatTimeout]: {
        title: 'Lost Signal',
        key: STATES.heartbeatTimeout,
        state: 'sessionDisconnected'
    },
    [STATES.expired]: {
        title: 'Extended',
        key: STATES.expired,
        state: STATES.expired
    }
};

export const DASHBOARD_STAT_TYPES = [
    STAT_TYPES[STATES.alarm],
    STAT_TYPES[STATES.alarmTimerStarted],
    STAT_TYPES[STATES.timerExpiring],
    STAT_TYPES[STATES.heartbeatTimeout],
    STAT_TYPES[STATES.expired]
];

export const DAILY_TOTALS_STAT_TYPES = [
    STAT_TYPES[ACTIONS.sessionStarted],
    STAT_TYPES[STATES.alarm],
    STAT_TYPES[ACTIONS.duress],
    STAT_TYPES[STATES.alarmTimerStarted],
    STAT_TYPES[STATES.heartbeatTimeout],
    STAT_TYPES[STATES.expired]
];

export const DASHBOARD_STATUS_OPTIONS = {
    ok: {
        msg: 'ALL CLEAR',
        class: 'active'
    },
    tether: {
        msg: 'ALARM ACTIVATED',
        class: 'alarmTimerStarted'
    },
    alarm: {
        msg: 'ALARM CONFIRMED',
        class: 'alarm'
    }
};

export const END_DATE_OFFSET_HOURS = 2;

export const CUSTOMER_CONFIG_FIELDS = [
    {
        field: 'name',
        label: 'Name',
        fullWidth: true,
        element: 'input',
        inputOptions: {
            type: 'text'
        }
    },
    {
        field: 'notes',
        label: 'Notes',
        fullWidth: true,
        element: 'textarea',
        inputOptions: {
            placeholder: 'Make a customer note'
        }
    },
    {
        field: 'expireSessionStrategy',
        label: 'Extended Session Action Plan Type',
        choices: [
            {
                label: 'Call User and End Session',
                value: 'callUser'
            },
            {
                label: 'Escalate to Law Enforcement',
                value: 'escalate'
            }
        ],
        fullWidth: true,
        default: 'escalate',
        helpText:
            'For Tether sessions - Whether to escalate to Law Enforcement or simply call the user when ending extended sessions.'
    },
    {
        field: 'expireSessionHours',
        label: 'Extended Session Timeout (in hours)',
        element: 'input',
        inputOptions: {
            type: 'number',
            step: 0.01,
            min: 0.01
        },
        helpText:
            "For Tether sessions - Number of hours before end users' sessions become extended and an action plan is enacted."
    },
    {
        field: 'heartbeatTimeoutMinutes',
        label: 'Lost Signal Timeout (in minutes)',
        element: 'input',
        inputOptions: {
            type: 'number',
            step: 1,
            min: 5
        },
        helpText:
            'For Tether sessions - When a user loses connection with the Monitoring Center, number of minutes before they will be reported as being in Lost Signal and an Action Plan will be enacted.'
    },
    {
        field: 'timerSessionHeartbeatTimeoutMinutes',
        label: 'Timer Session Lost Signal Timeout (in minutes)',
        element: 'input',
        inputOptions: {
            type: 'number',
            step: 1,
            min: 5,
            max: 9999
        },
        helpText:
            "For Timer sessions - When a user loses connection with the Monitoring Center, number of minutes before they will be reported as being in Lost Signal and an Action Plan will be enacted. If left blank, the minutes entered in the Tether setting above will be used. If this customer's users never want to be contacted for Lost Signal during a Timer session, enter 9999 here."
    },
    {
        field: 'emergContacts',
        label: 'Number of Supervisors',
        element: 'input',
        inputOptions: {
            type: 'number',
            step: 1,
            min: 0,
            max: 5
        }
    }
];

export const NOTIFICATION_FORMATS = {
    email: 'email',
    sms: 'sms'
};

export const NOTIFICATION_FORMATS_DISPLAY = {
    email: 'Email',
    sms: 'SMS'
};

export const MC_USER_SESSION_COOKIE_NAME = 'mc-user-session';

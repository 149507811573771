import '../../styling/PsapMap.css';

import GoogleMapReact from 'google-map-react';
import React, { useEffect, useState } from 'react';

import pinMap from '../../assets/images/ic_pin_map.png';
import { MAP_DEFAULTS } from '../../config/constants';
import CY from '../../config/cypressConstants';
import useSocket from '../../hooks/useSocket';

export default function PsapMap() {
    const [currentMap, setCurrentMap] = useState(null);
    const [googleMaps, setGoogleMaps] = useState(null);
    const [place, setPlace] = useState(null);
    const [marker, setMarker] = useState(null);
    const [psap, setPsap] = useState(null);
    const socket = useSocket({
        listeners: [
            [
                'psapResponse',
                (psapResponse) => {
                    if (psapResponse.fccId) {
                        setPsap(psapResponse);
                    }
                }
            ]
        ]
    });

    function closePsap() {
        setPlace(null);
    }

    function handleApiLoaded(currentMap, googleMaps) {
        setCurrentMap(currentMap);
        setGoogleMaps(googleMaps);

        // Create the search box and link it to the UI element.
        const input = document.getElementById('PsapMap-search');
        const searchBox = new googleMaps.places.SearchBox(input);

        currentMap.controls[googleMaps.ControlPosition.TOP_LEFT].push(input);

        // Bias the SearchBox results towards current map's viewport.
        currentMap.addListener('bounds_changed', () => {
            searchBox.setBounds(currentMap.getBounds());
        });

        searchBox.addListener('places_changed', () => {
            setPlace(null);
            let places = searchBox.getPlaces();
            // It's possible that there are multiple places.
            // We only care about one, next time the user should search more specifically
            if (places && places.length > 0) {
                let place = places[0];
                if (!place.geometry || !place.geometry.location) {
                    console.log('Returned place contains no geometry');
                    return;
                }
                currentMap.setCenter(place.geometry.location);
                setPlace(place.geometry.location);
            }
        });
    }

    useEffect(() => {
        setPsap(null);
        setMarker(null);
        if (marker) {
            marker.setMap(null);
        }
        if (place && currentMap && googleMaps) {
            let pin = new googleMaps.Marker({
                position: place
            });
            pin.setIcon(pinMap);
            pin.setMap(currentMap);
            setMarker(pin);
        }
    }, [place, currentMap, googleMaps]);

    useEffect(() => {
        if (!marker) {
            return;
        }
        socket.emit('psapByLatLng', marker.position);
    }, [marker, currentMap]);

    // We're defaulting to Austin - Home to AlertMedia
    const center = {
        lat: 30.269172,
        lng: -97.744167
    };
    return (
        <div className="PsapMap">
            <div
                className="PsapMap-map"
                data-testid={CY.PSAP_MAP_PAGE.PSAP_MAP}
            >
                <input
                    type="text"
                    id="PsapMap-search"
                    placeholder="Search for a location"
                    data-testid={CY.PSAP_MAP_PAGE.PSAP_MAP_SEARCH}
                />
                <GoogleMapReact
                    bootstrapURLKeys={MAP_DEFAULTS.bootstrapURLKeys}
                    center={center}
                    defaultZoom={MAP_DEFAULTS.psapZoom}
                    yesIWantToUseGoogleMapApiInternals={true}
                    options={MAP_DEFAULTS.options}
                    onGoogleApiLoaded={({ map, maps }) =>
                        handleApiLoaded(map, maps)
                    }
                    onClick={setPlace}
                />
                {psap && (
                    <div className="PsapMap-psapInfo">
                        <ul>
                            <li>
                                <div className="PsapMap-psapLabel">Agency:</div>
                                <div className="PsapMap-psapValue">
                                    {psap.agency}
                                </div>
                            </li>
                            <li>
                                <div className="PsapMap-psapLabel">Phone:</div>
                                <div className="PsapMap-psapValue">
                                    {psap.phone}
                                </div>
                            </li>
                            <li>
                                <div className="PsapMap-psapLabel">
                                    Coverage Area:
                                </div>
                                <div className="PsapMap-psapValue">
                                    {psap.coverage.area || 'Unknown'}
                                </div>
                            </li>
                            <li>
                                <div className="PsapMap-psapLabel">
                                    Coverage Exceptions:
                                </div>
                                <div className="PsapMap-psapValue">
                                    {psap.coverage.exceptions || 'Unknown'}
                                </div>
                            </li>
                            <li>
                                <div className="PsapMap-psapLabel">
                                    Address:
                                </div>
                                <div className="PsapMap-psapValue">
                                    {psap.mailingAddress.formattedAddress}
                                </div>
                            </li>
                            <li>
                                <div className="PsapMap-psapLabel">FCCID:</div>
                                <div className="PsapMap-psapValue">
                                    {psap.fccId}
                                </div>
                            </li>
                        </ul>
                        <div className="PsapMap-psapInfoClose">
                            <a
                                href="#"
                                onClick={closePsap}
                                data-testid={CY.PSAP_MAP_PAGE.PSAP_INFO_CLOSE}
                            >
                                Close
                            </a>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

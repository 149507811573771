import '../../styling/ActionPlanEditor.css';

import React from 'react';

import ActionPlanConfiguration from './ActionPlanConfiguration';
import CustomerConfiguration from './CustomerConfiguration';

export default function ActionPlanEditor() {
    return (
        <div id="ActionPlanEditor-page">
            <CustomerConfiguration />
            <ActionPlanConfiguration />
        </div>
    );
}

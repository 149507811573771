export const getUser = (store) => store.user;
export const getSessions = (store) => store.sessions;
export const getSessionsLoading = (store) => store.sessionsLoading;
export const getLogSearchParams = (store) => store.logSearchParams;
export const getLogSearchResults = (store) => store.logSearchResults;
export const getGroupedSessions = (store) => store.groupedSessions;
export const getSocket = (store) => store.socket;
export const getSessionToView = (store) => store.sessionToView;
export const getSessionToViewId = (store) => store.sessionToViewId;
// Action Plans
export const getSelectedCustomer = (store) => store.selectedCustomer;
export const getSelectedCustomerId = (store) => store.selectedCustomerId;
export const getSelectedPlan = (store) => store.selectedPlan;
export const getSelectedStep = (store) => store.selectedStep;

import { set } from 'lodash';
import { useState } from 'react';

/* 
    The benefit of this hook is that it allows you to group all form values together
     so they can be easily sent to the backend on submit/save, while also providing
     a handy helper function for updating one field within the formValues object
*/
export default function useFormValues(initialValue) {
    const [formValues, setFormValues] = useState(initialValue);
    const setOneValue = (key, val) =>
        setFormValues((currentFormValues) => {
            const newValues = { ...currentFormValues };
            set(newValues, key, val);
            return newValues;
        });

    return [formValues, setFormValues, setOneValue];
}

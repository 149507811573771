import { filter, findIndex, some } from 'lodash';

import {
    playDisconnected,
    playExtended,
    playPaused,
    playTetherPulled,
    playTimerExpiring,
    playUntended,
    stopUntended
} from '../utils';

import {
    ADD_NEW_SESSION,
    FIND_AND_REPLACE_SESSION,
    REMOVE_ENDED_SESSION,
    SET_GROUPED_SESSIONS,
    SET_LOG_SEARCH_PARAMS,
    SET_LOG_SEARCH_RESULTS,
    SET_SELECTED_CUSTOMER,
    SET_SELECTED_CUSTOMER_ID,
    SET_SELECTED_PLAN,
    SET_SELECTED_STEP,
    SET_SESSIONS,
    SET_SESSIONS_LOADING,
    SET_SESSION_TO_VIEW,
    SET_SESSION_TO_VIEW_ID,
    SET_SOCKET,
    SET_USER
} from './actionTypes';

const initialState = {
    groupedSessions: null,
    logSearchParams: null,
    logSearchResults: null,
    selectedCustomer: null,
    selectedCustomerId: null,
    selectedPlan: null,
    selectedStep: null,
    sessionToView: null,
    sessionToViewId: null,
    sessions: null,
    sessionsLoading: false,
    socket: null,
    user: null
};

export default function (state = initialState, action) {
    switch (action.type) {
        case ADD_NEW_SESSION: {
            return {
                ...state,
                sessions: [action.payload, ...state.sessions]
            };
        }
        case FIND_AND_REPLACE_SESSION: {
            const updatedSession = action.payload;

            /*  ------------------------------------------
                FIRST: Replace session in list of sessions 
                        (so Monitor list updates)
                ------------------------------------------ */
            // TODO: update this when sessionId is modified
            let index = findIndex(
                state.sessions,
                (s) =>
                    s.startDate === updatedSession.startDate &&
                    s.user?.userId === updatedSession.userId
            );
            let newSessions = [...state.sessions];
            let originalSession = {};

            // A little bit janky but, for now, bc new sessions coming from old safe signal
            //  come in like updates, we have to handle them like this rather than being able
            //  to use the ADD_NEW_SESSION reducer above
            if (index === -1) {
                newSessions.push(updatedSession);
            } else {
                originalSession = newSessions[index];
                newSessions[index] = updatedSession;
            }

            /*  -----------------------------------------------
                THEN: Let's see if we need to be playing sounds
                ----------------------------------------------- */
            const audioFnMap = {
                alarmTimerStarted: playTetherPulled,
                expired: playExtended,
                heartbeatTimeout: playDisconnected,
                paused: playPaused,
                timerExpiring: playTimerExpiring
            };
            let audioFn = audioFnMap[updatedSession.state];
            if (audioFn && originalSession.state !== updatedSession.state) {
                // We'll play the sound, but only if the state has changed
                audioFn();
            }

            // Let's also check if we need to be playing the alarm sound, or stopping it
            let unacknowledgedAlarms = some(
                newSessions,
                (s) => s.state === 'alarm' && !s.staffAssignee
            );
            unacknowledgedAlarms ? playUntended() : stopUntended();

            return {
                ...state,
                sessions: newSessions
            };
        }
        case REMOVE_ENDED_SESSION: {
            // TODO: update this when sessionId is modified
            const endedSession = action.payload;
            return {
                ...state,
                sessions: filter(
                    state.sessions,
                    (s) =>
                        !(
                            s.startDate === endedSession.startDate &&
                            s.user?.userId === endedSession.userId
                        )
                )
            };
        }
        case SET_GROUPED_SESSIONS: {
            return {
                ...state,
                groupedSessions: action.payload
            };
        }
        case SET_LOG_SEARCH_PARAMS: {
            return {
                ...state,
                logSearchParams: action.payload
            };
        }
        case SET_LOG_SEARCH_RESULTS: {
            return {
                ...state,
                logSearchResults: action.payload
            };
        }
        case SET_SELECTED_CUSTOMER: {
            return {
                ...state,
                selectedCustomer: action.payload
            };
        }
        case SET_SELECTED_CUSTOMER_ID: {
            return {
                ...state,
                selectedCustomerId: action.payload
            };
        }
        case SET_SELECTED_PLAN: {
            return {
                ...state,
                selectedPlan: action.payload
            };
        }
        case SET_SELECTED_STEP: {
            return {
                ...state,
                selectedStep: action.payload
            };
        }
        case SET_SESSION_TO_VIEW: {
            return {
                ...state,
                sessionToView: action.payload
            };
        }
        case SET_SESSION_TO_VIEW_ID: {
            return {
                ...state,
                sessionToViewId: action.payload
            };
        }
        case SET_SESSIONS: {
            return {
                ...state,
                sessions: action.payload
            };
        }
        case SET_SESSIONS_LOADING: {
            return {
                ...state,
                sessionsLoading: action.payload
            };
        }
        case SET_SOCKET: {
            return {
                ...state,
                socket: action.payload
            };
        }
        case SET_USER: {
            return {
                ...state,
                user: action.payload
            };
        }
        default:
            return state;
    }
}

import '../../../styling/SessionSummary.css';
import '../../../styling/Session-shared.css';

import dayjs from 'dayjs';
import { includes, toUpper } from 'lodash';
import React from 'react';
import { FaRegClock, FaRegFileAlt } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { Tooltip } from 'react-tooltip';

import {
    ACTIONS_DISPLAY,
    SESSION_TYPES,
    STATES,
    STATES_DISPLAY
} from '../../../config/constants';
import CY from '../../../config/cypressConstants';
import useSocket from '../../../hooks/useSocket';
import { setSessionToViewId } from '../../../redux/actions';
import { truncateString } from '../../../utils';

import Counter from './Counter';

export default function SessionSummary({ session }) {
    const dispatch = useDispatch();
    const socket = useSocket();

    function heartbeatShown() {
        return !(
            session.state === STATES.active ||
            session.state === STATES.sessionEnded
        );
    }

    function showAcknowledge() {
        let {
            alarm,
            alarmTimerStarted,
            expired,
            heartbeatTimeout,
            paused,
            sessionEnded
        } = STATES;
        if (!session || session.state === sessionEnded) {
            return false;
        }
        let validStates = [
            alarm,
            alarmTimerStarted,
            expired,
            heartbeatTimeout,
            paused
        ];
        return (
            session.actionPlan &&
            !session.staffAssignee &&
            includes(validStates, session.state)
        );
    }

    function showCounter() {
        return (
            session.sessionType === SESSION_TYPES.timer &&
            session.timerEndDate &&
            !sessionAlarming(session)
        );
    }

    function sessionAlarming() {
        return (
            session.state === STATES['alarm'] ||
            session.state === STATES['alarmTimerStarted']
        );
    }

    function createTooltipId(string) {
        return `${session.user.userId}${string}`;
    }

    function acknowledgeSession(session) {
        // TODO: update this when sessionId is modified
        socket.emit('acknowledgeSession', {
            startDate: session.startDate,
            user: session.user
        });
    }

    function renderSessionType() {
        const tooltipId = createTooltipId('sessionType');
        if (session.sessionType === SESSION_TYPES.timer) {
            return (
                <>
                    <FaRegClock
                        data-tooltip-id={tooltipId}
                        data-tooltip-content="Timer"
                    />
                    <Tooltip id={tooltipId} />
                </>
            );
        }
    }

    function toggleDetails() {
        dispatch(
            // TODO: update this when sessionId is modified
            setSessionToViewId({
                startDate: session.startDate,
                userId: session.user.userId
            })
        );
    }

    return (
        <div
            className="Session-summary"
            onClick={toggleDetails}
            data-testid={`${CY.MONITOR_PAGE.SESSION_SUMMARY_PANEL}-${session.startDate}`}
        >
            <div className={`Session-state ${session.state}-background`}>
                {session.staffAssignee ? (
                    <span>
                        Managed By
                        <br />
                        {session.staffAssignee.firstName}{' '}
                        {session.staffAssignee.lastName}
                    </span>
                ) : (
                    <span>{STATES_DISPLAY[session.state]}</span>
                )}
                {session.isHighRiskUser && (
                    <span className="Session-high-risk">HIGH RISK</span>
                )}
            </div>
            <div className="Session-body">
                <div
                    className="Session-start"
                    data-testid={CY.MONITOR_PAGE.SESSION_START_DATE}
                >
                    <h4>Session Start</h4>
                    <div>
                        <span
                            data-tooltip-id={createTooltipId('startDate')}
                            data-tooltip-content={session.address}
                        >
                            {dayjs(session.startDate).format('M/D/YY h:mm A')}
                        </span>
                        <Tooltip id={createTooltipId('startDate')} />

                        {/* TODO: figure out the correct icon */}
                        {session.notes && (
                            <>
                                <FaRegFileAlt
                                    data-tooltip-id={createTooltipId('notes')}
                                    data-tooltip-content={session.notes}
                                    aria-hidden="true"
                                />
                                <Tooltip id={createTooltipId('notes')} />
                            </>
                        )}
                    </div>
                </div>
                {showAcknowledge() && !session.staffAssignee && (
                    <div className="Session-acknowledge">
                        <button
                            type="button"
                            className="gray-button"
                            onClick={(e) => {
                                e.stopPropagation();
                                acknowledgeSession(session);
                            }}
                            data-testid={CY.BUTTONS.ACKNOWLEDGE_BUTTON}
                        >
                            Acknowledge
                        </button>
                    </div>
                )}
                <div
                    className="Session-person"
                    data-testid={CY.MONITOR_PAGE.SESSION_PERSON}
                >
                    <h4>Person</h4>
                    <span>
                        {session.user.firstName} {session.user.lastName}
                    </span>
                </div>
                <div
                    className="Session-company"
                    data-testid={CY.MONITOR_PAGE.SESSION_COMPANY}
                >
                    <h4>Company</h4>
                    <span
                        data-tooltip-id={session.user.userId + 'company'}
                        data-tooltip-content={session.user.customer.name}
                    >
                        {truncateString(session.user.customer.name, false, 20)}
                    </span>
                    <Tooltip id={session.user.userId + 'company'} />
                </div>
                <div
                    className="Session-heartbeat"
                    data-testid={CY.MONITOR_PAGE.SESSION_LAST_HEARTBEAT_DATE}
                >
                    <h4>Last Heartbeat</h4>
                    {heartbeatShown() ? (
                        <span>
                            {dayjs(session.heartbeat).format(
                                'M/D/YY h:mm:ss A'
                            )}
                        </span>
                    ) : (
                        <span>Actively Monitoring</span>
                    )}
                </div>
                <div
                    className="Session-action"
                    data-testid={CY.MONITOR_PAGE.SESSION_ACTION}
                >
                    <h4>Last User Action</h4>
                    <span
                        className={`${session.state}-text Session-action-text`}
                    >
                        {toUpper(ACTIONS_DISPLAY[session.action])}
                    </span>
                </div>
                {/* TODO: add countdown */}
                <div className={`${session.state}-text Session-counter`}>
                    {showCounter() ? (
                        <Counter
                            date={session.timerEndDate}
                            sessionType={session.sessionType}
                        />
                    ) : (
                        <Counter date={session.actionDate} />
                    )}
                </div>
                <div className="Session-type">{renderSessionType()}</div>
            </div>
        </div>
    );
}

import '../../../styling/SessionList.css';

import { forEach, isEmpty, map } from 'lodash';
import React from 'react';
import { FaSpinner } from 'react-icons/fa';
import { useSelector } from 'react-redux';

import mcSessionDetails from '../../../assets/images/mc-session-details.png';
import { SESSION_GROUP_ORDER } from '../../../config/constants';
import {
    getGroupedSessions,
    getSessions,
    getSessionsLoading
} from '../../../redux/selectors';
import { buildSessionId } from '../../../utils';

import SessionSummary from './SessionSummary';

export default function SessionList() {
    const sessionsLoading = useSelector(getSessionsLoading);
    const sessions = useSelector(getSessions);
    const groupedSessions = useSelector(getGroupedSessions);

    function renderSessions() {
        const renderedSessions = [];
        // Sessions are displayed grouped by state and the states are displayed in a specific
        //  order (basically, states that require attention at the top, active/non-alarm states
        //  at the bottom), determined by the constant SESSION_GROUP_ORDER
        forEach(SESSION_GROUP_ORDER, (state) => {
            if (!(state in groupedSessions)) return;
            renderedSessions.push(
                // Within each state group, the sessions are sorted by descending startDate
                // TODO: update this when sessionId is modified
                map(groupedSessions[state], (session) => (
                    <SessionSummary
                        key={buildSessionId(session)}
                        session={session}
                    />
                ))
            );
        });
        return renderedSessions;
    }

    return (
        <div className="SessionList-page">
            <div className="SessionList-container">
                <p id="SessionList-description">
                    <img
                        src={mcSessionDetails}
                        alt="Map marker with three horizontal dots dots"
                    />
                    Click on a session below to view details
                </p>

                {sessionsLoading && (
                    <div className="LargeSpinner">
                        <FaSpinner className="spinner" />
                    </div>
                )}
                {!sessionsLoading && isEmpty(sessions) && (
                    <p id="SessionList-empty">No active sessions to show.</p>
                )}
                {!sessionsLoading && !isEmpty(sessions) && renderSessions()}
            </div>
        </div>
    );
}

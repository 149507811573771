import React from 'react';
import { Provider } from 'react-redux';
import { createBrowserRouter } from 'react-router-dom';

import App from './components/App';
import ActionPlanEditor from './components/action-plans/ActionPlanEditor';
import Dashboard from './components/dashboard/Dashboard';
import SessionLog from './components/log/SessionLog';
import PsapMap from './components/psap-map/PsapMap';
import SessionList from './components/sessions/list/SessionList';
import store from './redux/store';

const routerConfigs = [
    {
        path: '/',
        element: (
            <Provider store={store}>
                <App />
            </Provider>
        ),
        children: [
            {
                path: '/',
                element: <SessionList />
            },
            {
                path: 'dashboard',
                element: <Dashboard />
            },
            {
                path: 'log',
                element: <SessionLog />
            },
            {
                path: 'action-plans',
                element: <ActionPlanEditor />
            },
            {
                path: 'psap-map',
                element: <PsapMap />
            }
        ]
    }
];

export default createBrowserRouter(routerConfigs);

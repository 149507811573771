import { sortBy } from 'lodash';
import { Multiselect } from 'multiselect-react-dropdown';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import useSocket from '../../hooks/useSocket';
import { getSelectedCustomer } from '../../redux/selectors';

export default function CustomerSearch({ selectedValue, onRemove, onSelect }) {
    // State
    const [customers, setCustomers] = useState([]);

    // Redux
    const selectedCustomer = useSelector(getSelectedCustomer);

    const socket = useSocket({
        listeners: [
            [
                'customers',
                (customers) => {
                    setCustomers(customers);
                }
            ]
        ]
    });

    useEffect(() => {
        socket.emit('getCustomers', {});
    }, []);

    return (
        <Multiselect
            ariaLabel="Customer"
            displayValue="name"
            id="customerSelect"
            listener="customers"
            onRemove={onRemove}
            onSelect={onSelect}
            placeholder="Select Customer"
            selectionLimit={1}
            singleSelect={true}
            selectedValues={selectedValue ? [selectedValue] : []}
            options={sortBy(customers, 'name')}
        />
    );
}

import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { FaSpinner } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';

import CY from '../../config/cypressConstants';
import usePrevious from '../../hooks/usePrevious';
import useSocket from '../../hooks/useSocket';
import { setSelectedStep } from '../../redux/actions';
import { getSelectedCustomerId, getSelectedPlan } from '../../redux/selectors';
import { replace } from '../../utils';

import StepForm from './StepForm';
import StepPicker from './StepPicker';

export default function StepConfiguration() {
    // State
    const [loading, setLoading] = useState(false);
    const [steps, setSteps] = useState([]);

    // Redux
    const customerId = useSelector(getSelectedCustomerId);
    const selectedPlan = useSelector(getSelectedPlan);
    const dispatch = useDispatch();

    // Refs
    const prevSelectedPlan = usePrevious(selectedPlan);

    // Hooks
    const socket = useSocket({
        listeners: [
            [
                'actionPlanSteps',
                (steps) => {
                    setSteps(steps);
                    // By default, let's "select" the first step
                    dispatch(setSelectedStep(steps[0]));
                    setLoading(false);
                }
            ],
            [
                'actionPlanStepUpdated',
                (step) =>
                    setSteps((currSteps) => replace(currSteps, step, 'name'))
            ]
        ]
    });

    useEffect(() => {
        if (selectedPlan?.name !== prevSelectedPlan?.name) {
            // Whenever the action plan changes, we should reset the step form
            setSteps([]);

            if (!isEmpty(selectedPlan)) {
                // If a new plan was selected, load its steps
                setLoading(true);
                socket.emit('getActionPlanSteps', {
                    actionPlanName: selectedPlan.name,
                    customerId
                });
            }
        }
    }, [selectedPlan]);

    if (loading) {
        return <FaSpinner className="spinner" />;
    }

    if (!selectedPlan || isEmpty(steps)) return null;

    return (
        <div>
            <h3 className="ActionPlanForm-label">Steps:</h3>
            <div
                id="ActionPlanStepForm"
                data-testid={CY.ACTION_PLANS_PAGE.STEP_FORM}
            >
                {/* --- Step picker widget --- */}
                <StepPicker steps={steps} />

                {/* --- Step form --- */}
                <StepForm />
            </div>
        </div>
    );
}

import dayjs from 'dayjs';
import { map } from 'lodash';
import React from 'react';
import { useDispatch } from 'react-redux';

import { ACTIONS_DISPLAY } from '../../config/constants';
import { setSessionToViewId } from '../../redux/actions';

export default function SessionLogTable({ sessions }) {
    const dispatch = useDispatch();

    function toggleDetails(session) {
        dispatch(
            setSessionToViewId({
                startDate: session.startDate,
                userId: session.user.userId
            })
        );
    }

    return (
        <table className="SessionLog-table">
            <thead>
                <tr>
                    <th>Session Start Date</th>
                    <th>Person</th>
                    <th>End Status</th>
                    <th>End Date</th>
                    <th>Session Acknowledged By</th>
                    <th>Closed By</th>
                </tr>
            </thead>
            <tbody>
                {map(sessions, (session) => (
                    <tr
                        key={`${session.startDate}-${session.userId}`}
                        onClick={() => toggleDetails(session)}
                        id="logResultsItem"
                    >
                        <td id="logResultStartDate">
                            {dayjs(session.startDate).format(
                                'M/D/YY h:mm:ss A'
                            )}
                        </td>
                        <td id="logResultUserFullName">
                            {session.user.firstName} {session.user.lastName}
                        </td>
                        <td id="logResultEndStatus">
                            <span className="duress-text">
                                {ACTIONS_DISPLAY[session.action]}
                            </span>
                        </td>
                        <td id="logResultEndDate">
                            {session.endDate &&
                                dayjs(session.endDate).format(
                                    'M/D/YY h:mm:ss A'
                                )}
                        </td>
                        <td id="logResultStaffAssignee">
                            {session.staffAssignee &&
                                `${session.staffAssignee.firstName}${' '}
                                                ${
                                                    session.staffAssignee
                                                        .lastName
                                                }`}
                        </td>
                        <td>
                            {session.endDate &&
                                (session.staffEndedBy ? (
                                    <span id="logResultStaffEndedBy">
                                        {session.staffEndedBy.firstName}{' '}
                                        {session.staffEndedBy.lastName}
                                    </span>
                                ) : (
                                    <span id="logResultsUserEnded">User</span>
                                ))}
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
}

import { isEmpty } from 'lodash';
import { Multiselect } from 'multiselect-react-dropdown';
import React, { useEffect, useRef, useState } from 'react';
import { FaSpinner } from 'react-icons/fa';

import useSocket from '../../hooks/useSocket';

export default function Autocomplete({
    ariaLabel,
    disable = false,
    displayValue,
    emitArgs = {},
    emitName,
    emitPathToSearchTerm,
    id,
    listener,
    onRemove,
    onSelect,
    placeholder,
    selectedValue,
    showLoading
}) {
    // State
    const [options, setOptions] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [showOptions, setShowOptions] = useState(true);
    const [loading, setLoading] = useState(false);
    // Refs
    const multiselectRef = useRef();

    // Redux
    const socket = useSocket({
        listeners: [[listener, (data) => setOptions(data)]]
    });

    useEffect(() => {
        // When the user clicks outside the input, clear searchTerm
        if (multiselectRef.current) {
            multiselectRef.current.onBlur = () => setSearchTerm('');
        }
    }, [multiselectRef]);

    useEffect(() => {
        // If the user has typed at least 3 chars, emit search to socket
        if (searchTerm?.length >= 3) {
            emitArgs[emitPathToSearchTerm] = searchTerm;
            socket.emit(emitName, emitArgs);
            if (showLoading) {
                setLoading(true);
            }
        }

        // Don't show results until user has typed 3 chars
        if (searchTerm?.length < 3 && isEmpty(options)) {
            setShowOptions(false);
        } else {
            setLoading(false);
            setShowOptions(true);
        }

        // When the search term is deleted, clear options
        if (!searchTerm) {
            setOptions([]);
            setShowOptions(false);
        }
    }, [searchTerm]);

    function onCustomerSearch(searchTerm) {
        setSearchTerm(searchTerm);
    }

    function handleSelect(selectedList, item) {
        onSelect(item);
        setOptions([]);
        setSearchTerm('');
    }

    return (
        <Multiselect
            aria-label={ariaLabel}
            disable={disable}
            displayValue={displayValue}
            id={id}
            onSearch={onCustomerSearch}
            onSelect={handleSelect}
            onRemove={onRemove}
            options={options}
            placeholder={placeholder}
            ref={multiselectRef}
            selectedValues={selectedValue ? [selectedValue] : []}
            selectionLimit={1}
            singleSelect={!!selectedValue}
            style={{
                inputField: { width: '100% ' },
                optionContainer: {
                    display: showOptions ? 'block' : 'none'
                }
            }}
            loading={loading}
            loadingMessage={'Loading...'}
        />
    );
}

import '../../styling/CustomerConfiguration.css';

import { find, isEmpty, map, pick } from 'lodash';
import { Multiselect } from 'multiselect-react-dropdown';
import React, { createElement, useEffect, useState } from 'react';
import { FaInfoCircle } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Tooltip } from 'react-tooltip';

import { CUSTOMER_CONFIG_FIELDS } from '../../config/constants';
import CY from '../../config/cypressConstants';
import useFormValues from '../../hooks/useFormValues';
import usePrevious from '../../hooks/usePrevious';
import useSocket from '../../hooks/useSocket';
import {
    setSelectedCustomer,
    setSelectedCustomerId
} from '../../redux/actions';
import { getSelectedCustomer, getUser } from '../../redux/selectors';
import CustomerSearch from '../shared/CustomerSearch';

export default function CustomerConfiguration() {
    // State
    const [customer, setCustomer] = useState(null); // Used to track original DB values
    const [formValues, setFormValues, setOneFormValue] = useFormValues(null); // Used to track user's changes

    // Redux
    const dispatch = useDispatch();
    const selectedCustomer = useSelector(getSelectedCustomer); // Used to hold Autocomplete selection
    const user = useSelector(getUser);

    // Hooks
    const prevSelectedCustomer = usePrevious(selectedCustomer);
    const socket = useSocket({
        listeners: [
            ['customerDetails', (data) => setCustomer(data)],
            ['customerUpdated', (data) => dispatch(setSelectedCustomer(data))]
        ]
    });

    useEffect(() => {
        if (isEmpty(selectedCustomer)) {
            // When the user unselects a customer, we need to reset the customer in state
            setCustomer(null);
        } else if (
            prevSelectedCustomer?.customerId !== selectedCustomer?.customerId
        ) {
            // When the user selectes a new customer from the dropdown, fetch that customer's details
            socket.emit('getCustomerDetails', {
                customerId: selectedCustomer.customerId,
                attributes: [
                    'customerId',
                    // We only need to bring back the fields that are actually needed for the form
                    ...map(CUSTOMER_CONFIG_FIELDS, ({ field }) => field)
                ]
            });
        }
    }, [selectedCustomer]);

    useEffect(() => {
        // Whenever the customer changes, we need to update the formValues and the customerId
        setFormValues(
            pick(
                customer,
                map(CUSTOMER_CONFIG_FIELDS, ({ field }) => field)
            )
        );
        dispatch(setSelectedCustomerId(customer?.customerId || null));
    }, [customer]);

    //destructuring selected as it is passed back as an array from MultiSelect
    function handleSelect([selected]) {
        setFormValues(null);
        dispatch(setSelectedCustomerId(null));
        dispatch(setSelectedCustomer(selected));
    }

    function handleRemove() {
        dispatch(setSelectedCustomer(null));
    }

    function _auditData() {
        let valid = true;

        if (!formValues.expireSessionStrategy) {
            toast.error('Extended Session Action Plan Type cannot be blank.');
            valid = false;
        }

        if (!formValues.name) {
            toast.error('Customer name cannot be blank.');
            valid = false;
        }

        return valid;
    }

    function saveChanges(e) {
        e.preventDefault();

        if (!_auditData()) return;

        socket.emit('updateCustomer', {
            customerId: customer.customerId,
            updatedValues: formValues,
            originalValues: customer,
            user
        });
    }

    function renderTooltip(configField) {
        const tooltipId = `${configField.field}-tooltip`;
        return (
            <>
                <FaInfoCircle
                    data-tooltip-id={tooltipId}
                    data-tooltip-content={configField.helpText}
                />
                <Tooltip id={tooltipId} style={{ maxWidth: '70vw' }} />
            </>
        );
    }

    return (
        <div
            id="CustomerConfiguration-page"
            className="ActionPlanEditor-section"
        >
            <h2>Customer Details</h2>
            <div className="CustomerConfiguration-filters">
                <CustomerSearch
                    onRemove={handleRemove}
                    onSelect={handleSelect}
                    selectedValue={selectedCustomer}
                />
            </div>

            {!isEmpty(formValues) && !isEmpty(customer) && (
                <form id="CustomerConfiguration-form" onSubmit={saveChanges}>
                    {map(CUSTOMER_CONFIG_FIELDS, (configField) => (
                        <div
                            key={configField.field}
                            className={`CustomerConfiguration-form-area ${
                                configField.fullWidth
                                    ? 'FullWidthInputs'
                                    : 'FlexInputs'
                            }`}
                        >
                            <div style={{ display: 'flex' }}>
                                {configField.helpText &&
                                    renderTooltip(configField)}

                                <label
                                    htmlFor={configField.label}
                                    className={
                                        configField.helpText
                                            ? 'LabelWithTooltip'
                                            : ''
                                    }
                                >
                                    {configField.label}:
                                </label>
                            </div>
                            {configField.choices ? (
                                <Multiselect
                                    id={configField.label}
                                    aria-label={configField.label}
                                    disable={!user.isMCAdmin}
                                    selectionLimit={1}
                                    options={configField.choices}
                                    onSelect={(selectedList, item) => {
                                        setOneFormValue(
                                            configField.field,
                                            item.value
                                        );
                                    }}
                                    onRemove={() => {
                                        setOneFormValue(
                                            configField.field,
                                            null
                                        );
                                    }}
                                    displayValue="label"
                                    singleSelect={true}
                                    selectedValues={
                                        customer[configField.field]
                                            ? [
                                                  find(configField.choices, {
                                                      value: customer[
                                                          configField.field
                                                      ]
                                                  })
                                              ]
                                            : []
                                    }
                                />
                            ) : (
                                createElement(configField.element, {
                                    ...configField.inputOptions,
                                    className: 'ActionPlanEditor-input',
                                    id: configField.label,
                                    value: formValues[configField.field],
                                    disabled: !user.isMCAdmin,
                                    onChange: (e) =>
                                        setOneFormValue(
                                            configField.field,
                                            e.target.value
                                        )
                                })
                            )}
                        </div>
                    ))}
                    {user.isMCAdmin && (
                        <div
                            className="CustomerConfiguration-form-area"
                            id="CustomerConfiguration-submit"
                        >
                            <input
                                type="submit"
                                value="Save Configuration"
                                className="button dark-blue"
                                data-testid={
                                    CY.ACTION_PLANS_PAGE
                                        .SAVE_CONFIGURATION_BUTTON
                                }
                            />
                        </div>
                    )}
                </form>
            )}
        </div>
    );
}

import { find, isEmpty, sortBy } from 'lodash';
import { Multiselect } from 'multiselect-react-dropdown';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useSocket from '../../hooks/useSocket';
import { setSelectedPlan } from '../../redux/actions';
import { getSelectedCustomerId, getSelectedPlan } from '../../redux/selectors';

export default function ActionPlanPicker() {
    // State
    const [actionPlans, setActionPlans] = useState([]);

    // Redux
    const customerId = useSelector(getSelectedCustomerId);
    const selectedPlan = useSelector(getSelectedPlan);
    const dispatch = useDispatch();

    // Refs
    const selectedPlanRef = useRef();
    selectedPlanRef.current = selectedPlan;

    const socket = useSocket({
        listeners: [
            [
                'customerActionPlans',
                (actionPlans) => {
                    setActionPlans(actionPlans);
                    // It's possible that the currently-selected action plan was updated;
                    //  we should replace it with the updated version
                    dispatch(
                        setSelectedPlan(
                            isEmpty(selectedPlanRef.current)
                                ? selectedPlanRef.current
                                : find(actionPlans, [
                                      'name',
                                      selectedPlanRef.current.name
                                  ])
                        )
                    );
                }
            ]
        ]
    });

    useEffect(() => {
        if (customerId) {
            // When user "selects" a customer, we should load the action plans
            socket.emit('getCustomerActionPlans', customerId);
        }
    }, []);

    function handleSelect(selectedList, item) {
        dispatch(setSelectedPlan(item));
    }

    function handleRemove() {
        dispatch(setSelectedPlan(null));
    }

    return (
        <Multiselect
            aria-label="Action Plans"
            displayValue="title"
            id="ActionPlanSelect"
            onSelect={handleSelect}
            onRemove={handleRemove}
            options={sortBy(actionPlans, 'name')}
            placeholder="Select a plan"
            selectedValues={selectedPlan ? [selectedPlan] : []}
            selectionLimit={1}
            singleSelect={true}
        />
    );
}

import { useEffect, useRef } from 'react';

/*
  This function is primarily for cases where you need to access the previous
    value of a state var in a context other than when it is being set (for instance,
    checking if a state var has changed since last render during a useEffect).
*/
export default function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    }, [value]);
    return ref.current;
}

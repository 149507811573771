import '../../../styling/SessionDetailsActionPlan.css';

import dayjs from 'dayjs';
import React, { useState } from 'react';
import { toast } from 'react-toastify';

import { ACTIONS_DISPLAY, STATES } from '../../../config/constants';
import CY from '../../../config/cypressConstants';
import useSocket from '../../../hooks/useSocket';

import SessionActionButtons from './SessionActionButtons';

export default function SessionActions({ session }) {
    // State
    const [endStatus, setEndStatus] = useState(null);
    const [emergencyDispatch, setEmergencyDispatch] = useState(false);
    const [showExtendSession, setShowExtendSession] = useState(false);
    const [extendSessionNote, setExtendSessionNote] = useState('');

    // Hooks
    const socket = useSocket({
        listeners: [
            [
                'endSessionDisposition',
                (disposition) => {
                    disposition
                        ? setEndStatus(null)
                        : toast.error('There was an error ending the session.');
                }
            ],
            [
                'extendSessionDisposition',
                (disposition) => {
                    if (disposition) {
                        setShowExtendSession(null);
                        setExtendSessionNote('');
                    } else {
                        toast.error(
                            'There was an error extending the session.'
                        );
                    }
                }
            ]
        ]
    });

    /* -------------- */
    /* EVENT HANDLERS */
    /* -------------- */
    function endSession() {
        // TODO: update this when sessionId is modified
        socket.emit('endSession', {
            emergencyDispatch,
            endStatus,
            startDate: session.startDate,
            user: session.user
        });

        // This component will pretty much always be unmounted when a session ends,
        //  but just in case we should probably clean up the state
        setEndStatus(null);
        setEmergencyDispatch(false);
    }

    function updateExtendSessionNote(e) {
        setExtendSessionNote(e.target.value);
    }

    function extendSession(amount) {
        let expirationDate = dayjs(session.expirationDate);
        expirationDate = expirationDate.add(amount, 'hours');
        // TODO: update this when sessionId is modified
        socket.emit('extendSession', {
            expirationDate,
            note: extendSessionNote,
            user: session.user,
            startDate: session.startDate
        });
    }

    function acknowledgeSession() {
        // TODO: update this when sessionId is modified
        socket.emit('acknowledgeSession', {
            startDate: session.startDate,
            user: session.user
        });
    }

    function endSessionProcess(endStatus, otherOptions = {}) {
        const { emergencyDispatch = false } = otherOptions;
        setEndStatus(endStatus);
        setEmergencyDispatch(emergencyDispatch);
    }

    /* -------------- */
    /* RENDER METHODS */
    /* -------------- */
    function renderConfirmEndSession() {
        return (
            <div className="SessionDetails-section ModifySessionLength">
                <div className="ModifySessionLength-header">
                    <h3>Are you sure you want to End Session?</h3>
                </div>
                <div className="ModifySessionLength-body">
                    <p>
                        This will end the session with a status of{' '}
                        {ACTIONS_DISPLAY[endStatus]}. This person will no longer
                        be monitored.
                    </p>
                </div>
                <div className="ModifySessionLength-footer">
                    <button
                        className="button dark-blue"
                        onClick={endSession}
                        data-testid={CY.SESSION_DETAILS.SESSION_END_BUTTON}
                    >
                        End Session
                    </button>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <button
                        className="button black"
                        onClick={() => setEndStatus(null)}
                        data-testid={
                            CY.SESSION_DETAILS.SESSION_CANCEL_END_BUTTON
                        }
                    >
                        Cancel
                    </button>
                </div>
            </div>
        );
    }

    function renderExtendSession() {
        return (
            <div className="SessionDetails-section ModifySessionLength">
                <div className="ModifySessionLength-header">
                    <h3>Extend Session?</h3>
                </div>
                <div className="ModifySessionLength-body">
                    <p>
                        Current Expiration:{' '}
                        {dayjs(session.expirationDate).format(
                            'M/D/YY h:mm:ss A'
                        )}{' '}
                    </p>
                    <form>
                        <input
                            type="text"
                            id="ExtendSession-notes-input"
                            aria-label="Extend Session Note"
                            placeholder="Enter reason for extending session time."
                            value={extendSessionNote}
                            onChange={updateExtendSessionNote}
                        />
                    </form>
                </div>
                <div className="ModifySessionLength-footer">
                    <button
                        disabled={extendSessionNote === ''}
                        className="button dark-blue"
                        onClick={() => extendSession(1)}
                    >
                        Add 1 hour
                    </button>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <button
                        disabled={extendSessionNote === ''}
                        className="button dark-blue"
                        onClick={() => extendSession(2)}
                    >
                        Add 2 hours
                    </button>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <button
                        disabled={extendSessionNote === ''}
                        className="button dark-blue"
                        onClick={() => extendSession(4)}
                    >
                        Add 4 hours
                    </button>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <button
                        onClick={() => setShowExtendSession(false)}
                        className="button black"
                    >
                        Cancel
                    </button>
                </div>
            </div>
        );
    }

    return (
        <div className="SessionDetails-section">
            {endStatus && renderConfirmEndSession()}
            {showExtendSession &&
                session.state !== STATES.sessionEnded &&
                renderExtendSession()}

            {!endStatus && !showExtendSession && (
                <SessionActionButtons
                    acknowledgeSession={acknowledgeSession}
                    endSessionProcess={endSessionProcess}
                    session={session}
                    setShowExtendSession={setShowExtendSession}
                />
            )}
        </div>
    );
}

import '../../styling/SessionLog.css';

// TODO: may need to tweak timezone logic for dayjs throughout this file
import dayjs from 'dayjs';
import { omit, orderBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import DateTimePicker from 'react-datetime-picker';
import { FaSpinner } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';

import { END_DATE_OFFSET_HOURS } from '../../config/constants';
import CY from '../../config/cypressConstants';
import useSocket from '../../hooks/useSocket';
import {
    setLogSearchParams,
    setLogSearchResults,
    setSelectedCustomer
} from '../../redux/actions';
import { getLogSearchParams, getLogSearchResults } from '../../redux/selectors';
import Autocomplete from '../shared/Autocomplete';
import CustomerSearch from '../shared/CustomerSearch';

import SessionLogTable from './SessionLogTable';

export default function SessionLog() {
    // State
    const [loading, setLoading] = useState(false);

    // Redux
    const dispatch = useDispatch();
    const searchParams = useSelector(getLogSearchParams);
    const searchResults = useSelector(getLogSearchResults);

    // Hooks
    const socket = useSocket({
        listeners: [
            [
                'sessionSearchResults',
                (sessionList) => {
                    setLoading(false);
                    dispatch(
                        setLogSearchResults(
                            orderBy(sessionList, 'startDate', 'desc')
                        )
                    );
                }
            ]
        ]
    });

    useEffect(() => {
        // On mount, if there is not already a start and end date in Redux store (aka first mount), we
        //  want to set the end date to the current time and start date to OFFSET_HOURS before that
        if (!searchParams?.startDate || !searchParams?.endDate) {
            dispatch(
                setLogSearchParams({
                    ...searchParams,
                    startDate: dayjs()
                        .subtract(END_DATE_OFFSET_HOURS, 'hour')
                        .toDate(),
                    endDate: dayjs().toDate()
                })
            );
        }
    }, []);

    //destructuring selected as it is passed back as an array from MultiSelect
    function updateCustomer([selectedValue]) {
        dispatch(
            setLogSearchParams({
                customer: selectedValue
            })
        );
    }

    function removeCustomer() {
        dispatch(setLogSearchParams(omit(searchParams, ['customer', 'user'])));
    }

    function updateUser(user) {
        dispatch(
            setLogSearchParams({
                ...searchParams,
                user
            })
        );
    }

    function removeUser() {
        dispatch(setLogSearchParams(omit(searchParams, 'user')));
    }

    function updateStartDate(value) {
        let update = {
            startDate: value
        };
        if (dayjs(value).isAfter(searchParams?.endDate)) {
            update['endDate'] = dayjs(value)
                .add(END_DATE_OFFSET_HOURS, 'hour')
                .toDate();
        }
        dispatch(
            setLogSearchParams({
                ...searchParams,
                ...update
            })
        );
    }

    function updateEndDate(value) {
        dispatch(
            setLogSearchParams({
                ...searchParams,
                endDate: value
            })
        );
    }

    function fetchSessions(e) {
        e.preventDefault();
        setLoading(true);
        socket.emit('getSessionSearchResults', {
            ...searchParams,
            customerId: searchParams?.customer?.customerId
        });
    }

    function renderResults() {
        if (!searchResults) return;
        if (searchResults.length === 0) {
            return <p className="SessionLog-empty">No results</p>;
        } else {
            return <SessionLogTable sessions={searchResults} />;
        }
    }

    return (
        <div className="SessionLog-page">
            <form className="SessionLog-filters" onSubmit={fetchSessions}>
                <CustomerSearch
                    onRemove={removeCustomer}
                    onSelect={updateCustomer}
                    selectedValue={searchParams?.customer}
                />

                <Autocomplete
                    ariaLabel="User"
                    disable={!searchParams?.customer}
                    displayValue="label"
                    emitArgs={{
                        customerId: searchParams?.customer?.customerId
                    }}
                    emitName="getUsers"
                    emitPathToSearchTerm="name"
                    id="userSelect"
                    listener="users"
                    onRemove={removeUser}
                    onSelect={updateUser}
                    placeholder="Type to search users"
                    selectedValue={searchParams?.user}
                    showLoading={true}
                />

                <label htmlFor="logStartDateBox">between</label>

                <DateTimePicker
                    id="logStartDateBox"
                    value={searchParams?.startDate}
                    onChange={updateStartDate}
                    clearIcon={null}
                    data-cy={CY.LOG_PAGE.CALENDAR_PICKER_START_DATE}
                />

                <label htmlFor="logEndDateBox">and</label>

                <DateTimePicker
                    id="logEndDateBox"
                    value={searchParams?.endDate}
                    onChange={updateEndDate}
                    clearIcon={null}
                    data-testid={CY.LOG_PAGE.CALENDAR_PICKER_END_DATE}
                />

                <button
                    type="submit"
                    disabled={
                        !searchParams?.customer ||
                        searchParams?.startDate > searchParams?.endDate ||
                        loading
                    }
                    className="button dark-gray"
                    id="logSearchButton"
                    data-testid={CY.BUTTONS.SEARCH_BUTTON}
                >
                    {loading ? <FaSpinner className="spinner" /> : 'Search'}
                </button>
            </form>
            <div className="SessionLog-results">
                {loading ? <FaSpinner className="spinner" /> : renderResults()}
            </div>
        </div>
    );
}

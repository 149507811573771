import dayjs from 'dayjs';
import { get, map } from 'lodash';
import React from 'react';

import { DAILY_TOTALS_STAT_TYPES } from '../../config/constants';
import CY from '../../config/cypressConstants';

export default function DailyTotals({ stats = {} }) {
    return (
        <div className="Dashboard-section" id="DailyTotals-section">
            <div className="Dashboard-section-header">
                <h2>Daily Totals as of {dayjs().format('h:mm a')}</h2>
            </div>
            <dl className="Dashboard-section-body">
                {map(DAILY_TOTALS_STAT_TYPES, (type, ix) => (
                    <div
                        className={`Dashboard-section-cell ${
                            ix % 2 === 0 && 'even' // Used for styling the border
                        }`}
                        key={type.key}
                        data-testid={`${CY.DASHBOARD_PAGE.DASHBOARD_STAT_CELL}-${type.state}`}
                    >
                        <div
                            className={`Dashboard-stats-title ${
                                type.state ? `${type.state}-text` : ''
                            }`}
                        >
                            <dt>{type.title}</dt>
                        </div>
                        <div className="Dashboard-stats-content">
                            <dd>{get(stats, type.key, 0)}</dd>
                        </div>
                    </div>
                ))}
            </dl>
        </div>
    );
}

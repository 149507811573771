import React from 'react';
import reactDom from 'react-dom';
import { RouterProvider } from 'react-router-dom';

import router from './router';

reactDom.render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>,
    document.getElementById('root')
);

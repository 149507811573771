import '../styling/reset.css';
import '../styling/styles.css';
// NOTE: I don't understand why these have to be manually imported...
//        In IC, they are not, but the styles still get applied. But
//        here, I couldn't get the default styling to work without the
//        manual imports
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import 'react-toastify/dist/ReactToastify.css';

import axios from 'axios';
import { Buffer } from 'buffer';
import React, { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { io } from 'socket.io-client';

import { MC_USER_SESSION_COOKIE_NAME } from '../config/constants';
import useSessions from '../hooks/useSessions';
import useSocket from '../hooks/useSocket';
import { setSocket, setUser } from '../redux/actions';
import { getSessionToViewId } from '../redux/selectors';

import Header from './Header';
import Login from './Login';
import SessionDetails from './sessions/details/SessionDetails';

const App = () => {
    // Redux
    const sessionToViewId = useSelector(getSessionToViewId);
    const socket = useSocket();
    const dispatch = useDispatch();

    // Hooks
    const [cookies, _setCookie, removeCookie] = useCookies([
        MC_USER_SESSION_COOKIE_NAME
    ]);
    // We don't actually need to access the sessions or loading values in App.js, we just need to set up the event listeners.
    //  In the future, it might make sense to either remove the return from the hook, or make a "dumb" version of the hook that
    //  simply returns Redux store values without registering/deregistering socket listeners
    useSessions();

    function initializeSocket() {
        // TODO: add auth into this (see makeSocketConnection in IC)
        const socketConnection = io();
        socketConnection.on('connect', () => {
            dispatch(setSocket(socketConnection));

            // Register global toast listener
            socketConnection.on('updateDisposition', (disposition) => {
                console.log('updateDisposition', disposition);
                disposition
                    ? toast.success('Updates saved successfully.')
                    : toast.error('There was an error saving your changes.');
            });

            // Register other global listeners
            // Sessions can be acknowledged from various screens; we need the listener here so
            //  the event can be caught no matter which page the user is on
            socketConnection.on('acknowledgeSessionFailed', () =>
                toast.error('There was an error acknowledging the session.')
            );
        });

        socketConnection.on('disconnect', () => {
            dispatch(setSocket(null));
        });
    }

    async function logOut() {
        // TODO: are there other things that need to happen here after successfully logging out?
        try {
            const res = await axios.post('/api/auth/staff/logout');
            if (res.status === 200) {
                socket.disconnect();
                dispatch(setUser(null));
                dispatch(setSocket(null));
                removeCookie(MC_USER_SESSION_COOKIE_NAME);
            }
        } catch (err) {
            console.error(`Error logging out: ${JSON.stringify(err)}`);
        }
    }

    useEffect(() => {
        if (cookies[MC_USER_SESSION_COOKIE_NAME]) {
            let user = JSON.parse(
                Buffer.from(
                    cookies[MC_USER_SESSION_COOKIE_NAME],
                    'base64'
                ).toString('utf8')
            );
            dispatch(setUser(user));
            initializeSocket();
        }
    }, [cookies]);

    return (
        <div id="MC">
            <Header logOut={logOut} />
            <div className="MC-Body">
                {socket ? <Outlet /> : <Login onLogin={initializeSocket} />}
            </div>
            {sessionToViewId && <SessionDetails />}
            <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar={true}
            />
        </div>
    );
};

export default App;

import '../../../styling/Session-shared.css';
import '../../../styling/SessionLocations.css';

import GoogleMapReact from 'google-map-react';
import { forEach, isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';

import breadcrumbPin from '../../../assets/images/ic_dot_map_default.png';
import currentPin from '../../../assets/images/ic_pin_map_current.png';
import startPin from '../../../assets/images/ic_pin_map_start.png';
import { MAP_DEFAULTS } from '../../../config/constants';

export default function SessionLocations({
    startLocation,
    currentLocation,
    breadcrumbLocations
}) {
    const [currentMap, setCurrentMap] = useState(null);
    const [googleMaps, setGoogleMaps] = useState(null);
    const [markers, setMarkers] = useState(null);
    const [didInitialLoad, setDidInitialLoad] = useState(false);
    const [center, setCenter] = useState(
        currentLocation
            ? {
                  lat: parseFloat(currentLocation.latitude),
                  lng: parseFloat(currentLocation.longitude),
                  accuracy: currentLocation.accuracy
              }
            : startLocation
            ? {
                  lat: parseFloat(startLocation.latitude),
                  lng: parseFloat(startLocation.longitude),
                  accuracy: startLocation.accuracy
              }
            : // If all else fails, we're defaulting to Austin - Home to AlertMedia
              {
                  lat: 30.269172,
                  lng: -97.744167
              }
    );

    useEffect(() => {
        if (currentMap && googleMaps && startLocation) {
            if (markers) {
                // Let's make sure we clear all previous markers
                forEach(markers, (marker) => {
                    marker.setMap(null);
                });
            }
            let placedMarkers = [];
            let bounds = new googleMaps.LatLngBounds();
            if (startLocation) {
                let startMarker = new googleMaps.Marker({
                    position: {
                        lat: parseFloat(startLocation.latitude),
                        lng: parseFloat(startLocation.longitude)
                    },
                    map: currentMap,
                    icon: startPin
                });
                placedMarkers.push(startMarker);
                bounds.extend(startMarker.getPosition());
            }

            if (breadcrumbLocations) {
                forEach(breadcrumbLocations, (breadcrumb) => {
                    let breadcrumbMarker = new googleMaps.Marker({
                        position: {
                            lat: parseFloat(breadcrumb.latitude),
                            lng: parseFloat(breadcrumb.longitude)
                        },
                        map: currentMap,
                        icon: breadcrumbPin
                    });
                    placedMarkers.push(breadcrumbMarker);
                    bounds.extend(breadcrumbMarker.getPosition());
                });
            }

            if (
                currentLocation &&
                (currentLocation.address !== startLocation?.address ||
                    !isEmpty(breadcrumbLocations))
            ) {
                let currentMarker = new googleMaps.Marker({
                    position: {
                        lat: parseFloat(currentLocation.latitude),
                        lng: parseFloat(currentLocation.longitude)
                    },
                    map: currentMap,
                    icon: currentPin
                });
                placedMarkers.push(currentMarker);
                bounds.extend(currentMarker.getPosition());
            }
            setMarkers(placedMarkers);
            if (startLocation && !didInitialLoad) {
                panToLocation(startLocation);
            }

            if (currentLocation && !didInitialLoad) {
                panToLocation(currentLocation);
                setDidInitialLoad(true);
            }
        }
    }, [
        currentMap,
        googleMaps,
        startLocation,
        breadcrumbLocations,
        currentLocation
    ]);

    function panToLocation(location) {
        setCenter({
            lat: parseFloat(location.latitude),
            lng: parseFloat(location.longitude)
        });
    }

    function handleApiLoaded(currentMap, googleMaps) {
        setCurrentMap(currentMap);
        setGoogleMaps(googleMaps);
    }

    if (!startLocation) return null;

    return (
        <div className="SessionDetails-section SessionLocations-section">
            <h3 className="SessionLocations-header">Locations</h3>
            <div className="SessionLocations-body">
                <div className="column">
                    <dl>
                        {currentLocation && (
                            <div>
                                <dt>
                                    Current Location
                                    {currentLocation.accuracy != null && (
                                        <div>
                                            (Accuracy{' '}
                                            {currentLocation?.accuracy})
                                        </div>
                                    )}
                                </dt>
                                <dd
                                    onClick={() =>
                                        panToLocation(currentLocation)
                                    }
                                >
                                    {currentLocation?.address}
                                    <br />
                                    {currentLocation?.sublocality}
                                </dd>
                            </div>
                        )}
                        <div>
                            <dt>
                                Starting Location
                                {startLocation.accuracy != null && (
                                    <div>
                                        (Accuracy {startLocation?.accuracy})
                                    </div>
                                )}
                            </dt>
                            <dd onClick={() => panToLocation(startLocation)}>
                                {startLocation?.address}
                                <br />
                                {startLocation?.sublocality}
                            </dd>
                        </div>
                    </dl>
                    {currentLocation &&
                        currentLocation?.address !== startLocation?.address && (
                            <p id="SessionLocations-different">
                                CURRENT LOCATION IS DIFFERENT FROM START
                                LOCATION
                            </p>
                        )}
                </div>
                <div className="column" id="SessionLocations-map">
                    <GoogleMapReact
                        bootstrapURLKeys={MAP_DEFAULTS.bootstrapURLKeys}
                        center={center}
                        defaultZoom={MAP_DEFAULTS.defaultZoom}
                        yesIWantToUseGoogleMapApiInternals={true}
                        options={MAP_DEFAULTS.options}
                        onGoogleApiLoaded={({ map, maps }) =>
                            handleApiLoaded(map, maps)
                        }
                    />
                </div>
            </div>
        </div>
    );
}

import { groupBy, mapValues, orderBy } from 'lodash';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { STATES } from '../config/constants';
import useSocket from '../hooks/useSocket';
import {
    findAndReplaceSession,
    removeEndedSession,
    setGroupedSessions,
    setSessionToView,
    setSessions,
    setSessionsLoading
} from '../redux/actions';
import {
    getGroupedSessions,
    getSessionToViewId,
    getSessions,
    getSessionsLoading
} from '../redux/selectors';

export default function useSessions() {
    // Redux
    const dispatch = useDispatch();
    const sessionToViewId = useSelector(getSessionToViewId);
    const sessions = useSelector(getSessions);
    const sessionsLoading = useSelector(getSessionsLoading);
    const groupedSessions = useSelector(getGroupedSessions);

    // Refs
    const sessionToViewIdRef = useRef();
    sessionToViewIdRef.current = sessionToViewId;

    // Hooks
    const socket = useSocket({
        listeners: [
            [
                'sessionList',
                (data) => {
                    dispatch(setSessions(data));
                    dispatch(setSessionsLoading(false));
                }
            ],
            [
                'sessionUpdate',
                (data) => {
                    // Replace the session in the list of sessions
                    dispatch(findAndReplaceSession(data));

                    // If the user is currently viewing this session, update sessionToView
                    // TODO: update when sessionId is modified
                    if (
                        sessionToViewIdRef.current?.userId === data.userId &&
                        sessionToViewIdRef.current?.startDate === data.startDate
                    ) {
                        dispatch(setSessionToView(data));
                    }

                    // Decide whether the session should be removed
                    if (_shouldRemoveEndedSession(data)) {
                        setTimeout(
                            () => dispatch(removeEndedSession(data)),
                            30000
                        );
                    }
                }
            ]
        ]
    });

    // On page load, if there aren't already sessions loaded (i.e. from another
    //   component that also uses this hook), emit to get the session list
    useEffect(() => {
        if (socket && sessions === null) {
            dispatch(setSessionsLoading(true));
            socket.emit('getSessionList');
        }
    }, [socket, sessions]);

    // Whenever sessions change, update grouped sessions as well
    useEffect(() => {
        dispatch(setGroupedSessions(_groupAndSortSessions(sessions)));
    }, [sessions]);

    return [sessionsLoading, sessions, groupedSessions];
}

function _groupAndSortSessions(sessions) {
    // Group sessions by state, then for each state sort them by -startDate
    return mapValues(groupBy(sessions, 'state'), (sessionList) =>
        orderBy(sessionList, ['startDate'], ['desc'])
    );
}

function _shouldRemoveEndedSession(update) {
    return update.state === STATES.sessionEnded;
}

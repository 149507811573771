import '../../styling/ActionPlanConfiguration.css';

import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setSelectedPlan, setSelectedStep } from '../../redux/actions';
import { getSelectedCustomerId, getSelectedPlan } from '../../redux/selectors';

import ActionPlanPicker from './ActionPlanPicker';
import ActionPlanTitleForm from './ActionPlanTitleForm';
import StepConfiguration from './StepConfiguration';

export default function ActionPlanConfiguration() {
    // Redux
    const customerId = useSelector(getSelectedCustomerId);
    const selectedPlan = useSelector(getSelectedPlan);
    const dispatch = useDispatch();

    useEffect(() => {
        // When the customer is "unselected", any selected plan needs to also be
        //  reset. This needs to be handled here in the parent component because
        //  when customerId is falsey, the children who own the logic for selecting
        //  plans and steps unmount.
        if (!customerId) {
            dispatch(setSelectedPlan(null));
        }
    }, [customerId]);

    useEffect(() => {
        // When a plan is "unselected", the selected step needs to also be reset.
        //  This needs to be handled here in the parent component because when
        //  selectedPlan is falsey, the children who own the logic for selecting
        //  steps unmount.
        if (isEmpty(selectedPlan)) {
            dispatch(setSelectedStep(null));
        }
    }, [selectedPlan]);

    return (
        <div
            id="ActionPlanConfiguration-page"
            className="ActionPlanEditor-section"
        >
            <h2>Action Plan Definitions</h2>

            {!customerId ? (
                <p>Select a customer to begin.</p>
            ) : (
                <div>
                    {/* --- Selector for action plans --- */}
                    <ActionPlanPicker />

                    {/* --- Action Plan Form --- */}
                    {selectedPlan && (
                        <div>
                            <ActionPlanTitleForm />
                            <StepConfiguration />
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

import { forEach } from 'lodash';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { getSocket } from '../redux/selectors';

export default function useSocket(params = {}) {
    const { listeners = [], emits = [] } = params;
    const socket = useSelector(getSocket);

    useEffect(() => {
        // When the socket is instantiated, register listeners
        if (socket) {
            forEach(listeners, ([event, callback]) => {
                socket.on(event, callback);
            });

            forEach(emits, ([event, params = null]) =>
                socket.emit(event, params)
            );
        }
    }, [socket]);

    useEffect(
        () =>
            // When the component ummounts, deregister socket listeners
            () => {
                forEach(listeners, ([event, _callback]) => {
                    console.log('deregistering event', event);
                    socket.off(event);
                });
            },
        []
    );

    return socket;
}
